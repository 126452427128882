export class BookItem {
  private _book_key: number | null = null;
  public get book_key(): number | null {
    return this._book_key;
  }
  public set book_key(value: number | null) {
    this._book_key = value;
  }

  private _account: string | null = null;
  public get account(): string | null {
    return this._account;
  }
  public set account(value: string | null) {
    this._account = value;
  }

  private _bank: string | null = null;
  public get bank(): string | null {
    return this._bank;
  }
  public set bank(value: string | null) {
    this._bank = value;
  }

  private _cin: string | null = null;
  public get cin(): string | null {
    return this._cin;
  }
  public set cin(value: string | null) {
    this._cin = value;
  }

  private _city: string | null = null;
  public get city(): string | null {
    return this._city;
  }
  public set city(value: string | null) {
    this._city = value;
  }
  
  private _city_invoice: string | null = null;
  public get city_invoice(): string | null {
    return this._city_invoice;
  }
  public set city_invoice(value: string | null) {
    this._city_invoice = value;
  }

  private _company_key: number | null = null;
  public get company_key(): number | null {
    return this._company_key;
  }
  public set company_key(value: number | null) {
    this._company_key = value;
  }

  private _company: string | null = null;
  public get company(): string | null {
    return this._company;
  }
  public set company(value: string | null) {
    this._company = value;
  }

  private _company_invoice: string | null = null;
  public get company_invoice(): string | null {
    return this._company_invoice;
  }
  public set company_invoice(value: string | null) {
    this._company_invoice = value;
  }

  private _country: string | null = null;
  public get country(): string | null {
    return this._country;
  }
  public set country(value: string | null) {
    this._country = value;
  }

  private _country_invoice: string | null = null;
  public get country_invoice(): string | null {
    return this._country_invoice;
  }
  public set country_invoice(value: string | null) {
    this._country_invoice = value;
  }

  private _iban: string | null = null;
  public get iban(): string | null {
    return this._iban;
  }
  public set iban(value: string | null) {
    this._iban = value;
  }
  
  private _loading_at_weekend: boolean | null = null;
  public get loading_at_weekend(): boolean | null {
    return this._loading_at_weekend;
  }
  public set loading_at_weekend(value: boolean | null) {
    this._loading_at_weekend = value;
  }

  private _loading_at_work_days: boolean | null = null;
  public get loading_at_work_days(): boolean | null {
    return this._loading_at_work_days;
  }
  public set loading_at_work_days(value: boolean | null) {
    this._loading_at_work_days = value;
  }

  private _maturity: number | null = null;
  public get maturity(): number | null {
    return this._maturity;
  }
  public set maturity(value: number | null) {
    this._maturity = value;
  }

  private _street: string | null = null;
  public get street(): string | null {
    return this._street;
  }
  public set street(value: string | null) {
    this._street = value;
  }
  
  private _street_invoice: string | null = null;
  public get street_invoice(): string | null {
    return this._street_invoice;
  }
  public set street_invoice(value: string | null) {
    this._street_invoice = value;
  }

  private _swift: string | null = null;
  public get swift(): string | null {
    return this._swift;
  }
  public set swift(value: string | null) {
    this._swift = value;
  }

  private _tin: string | null = null;
  public get tin(): string | null {
    return this._tin;
  }
  public set tin(value: string | null) {
    this._tin = value;
  }

  private _updated: string | null = null;
  public get updated(): string | null {
    return this._updated;
  }
  public set updated(value: string | null) {
    this._updated = value;
  }

  private _work_day_begin: string | null = null;
  public get work_day_begin(): string | null {
    return this._work_day_begin;
  }
  public set work_day_begin(value: string | null) {
    this._work_day_begin = value;
  }

  private _work_day_end: string | null = null;
  public get work_day_end(): string | null {
    return this._work_day_end;
  }
  public set work_day_end(value: string | null) {
    this._work_day_end = value;
  }
  
  private _zip: string | null = null;
  public get zip(): string | null {
    return this._zip;
  }
  public set zip(value: string | null) {
    this._zip = value;
  }

  private _zip_invoice: string | null = null;
  public get zip_invoice(): string | null {
    return this._zip_invoice;
  }
  public set zip_invoice(value: string | null) {
    this._zip_invoice = value;
  }

  get order_comp_book_formatted(): string | null {
    return this.company + ', ' + this.city + ', ' + this.country;
  }
}
