import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Itinerary } from 'src/app/model/itinerary.object';
import { Order } from 'src/app/model/order.object';
import { AlertService } from 'src/app/service/alert.service';
import { LocalJsonService } from 'src/app/service/local-json.service';
import { ObligationService } from 'src/app/service/obligation.service';
import { DateTools } from 'src/app/tools/date-tools';

@Component({
  selector: 'app-itinerary-confirmation',
  templateUrl: './itinerary-confirmation.component.html',
  styleUrls: ['./itinerary-confirmation.component.css'],
  providers: [
    ObligationService
  ]
})
export class ItineraryConfirmationComponent implements OnInit, OnDestroy {

  private _subscribed: Array<Subscription> = [];
  
  private _itinerary: Itinerary | null = null;
  @Input()
  public set itinerary(value: Itinerary | null) {
    this._itinerary = value;
    // reinit
    this.it_arrival_time = null;
    this.it_weight = null;
    this.it_length = null;
    this.it_containers_out = null;
    this.it_containers_in = null;
    this.it_descr = null;

    if (this._itinerary) {
      this.it_arrival_time = this._itinerary.arrival_time_custom;
      this.it_weight = this._itinerary.weight;
      this.it_length = this._itinerary.length;
      if (this._itinerary.type == 'L') {
        this.it_containers_in = this._itinerary.ware_pcs;
      }
      else if (this._itinerary.type == 'U') {
        this.it_containers_out = this._itinerary.ware_pcs;
      }
    }
  }
  public get itinerary(): Itinerary | null {
    return this._itinerary;
  }

  private _token: string | null = null;
  public get token(): string | null {
    return this._token;
  }
  @Input()
  public set token(value: string | null) {
    this._token = value;
  }
  
  private _order: Order | null = null;
  public get order(): Order | null {
    return this._order;
  }
  @Input()
  public set order(value: Order | null) {
    this._order = value;
    if (this._order && this._order.order_comp_book && this._order.order_comp_book.country) {
      this.country = this._order.order_comp_book.country;
      if (!['CZ', 'SK', 'EN', 'DE', 'IT', 'ES', 'PL'].includes(this.country)) {
        // out of our dictionary - use EN
        this.country = 'EN';
      }
    }
  }

  @Output() completed: EventEmitter<Itinerary> = new EventEmitter<Itinerary>();

  // itinerary attributes
  public it_arrival_time: string | null = null;
  public it_weight: number | null = null;
  public it_length: number | null = null;
  public it_containers_out: number | null = null;
  public it_containers_in: number | null = null;
  public it_descr: string | null = null;

  public dictionary: any = null;
  public country: string = 'CZ';

  constructor(
    private _obligationServ: ObligationService,
    private _localJsonServ: LocalJsonService,
    private _alertServ: AlertService
  ) {
  }

  ngOnInit(): void {
    this._subscribed.push(
      this._localJsonServ.getDiaryDictionary().subscribe({
        next: (response) => {
          this.dictionary = response;
        },
        error: (err) => {
          console.log(err);
          this._alertServ.alert('Nepodařilo se načíst slovník.', 'danger', 4000);
        }
      })
    );
  }
  
  ngOnDestroy() {
    this._subscribed.forEach(
      subsriber => {
        subsriber.unsubscribe();
      }
    );
    this._subscribed = [];
  }


  /************************************************/
  /* Simple validations */
  /************************************************/
  weightValidate(): void {
    if (this.it_weight) {
      if (this.it_weight >= 100) {
        this._alertServ.alert(this.dictionary['MaxHmotnost'][this.country], 'danger', 4000);
        this.it_weight = 99;
      }
    }
  }

  lengthValidate(): void {
    if (this.it_length) {
      if (this.it_length >= 100) {
        this._alertServ.alert(this.dictionary['MaxDelka'][this.country], 'danger', 4000);
        this.it_length = 99;
      }
    }
  }

  descrValidate(): void {
    if (this.it_descr && this.it_descr.length > 100) {
      alert(this.dictionary['MaxDelka'][this.country]);
      this.it_descr = this.it_descr.substring(0, 99);
    }
  }


  /************************************************/
  /* Completing itinerary */
  /************************************************/
  // using PUT api
  completeItinerary(): void {
    if (this._itinerary) {
      let data: any = {
        // @ts-ignore
        time: DateTools.toIsoWithoutMilisec(new Date(this.it_arrival_time)),
        weight: this.it_weight,
        length: this.it_length,
        containers_in: this.it_containers_in,
        containers_out: this.it_containers_out,
        descr: this.it_descr,
        type: this._itinerary.type
      };

      if (this._itinerary.type == "W" || this._itinerary.type == "V") {
        if (this._itinerary.warehouse_in_itinerary) {
          // collection -> unloading
          data.type = "U";
        }
        else if (this._itinerary.warehouse_out_itinerary) {
          // delivery -> loading
          data.type = "L";
        }
      }
  
      let obligation_key = this._itinerary.obligation_key;
      let itin_key = this._itinerary.itinerary_key;
  
      this._subscribed.push(
        this._obligationServ.completeItinerary(this._token, obligation_key, itin_key, data).subscribe({
          next: (response) => {
            // log only on localhost
            if (!window.location.href.match(/ext2.truckmanager.eu/)) {
              console.log(response);
            }
            // NOCONTENT -> itinerary has been already completed
            if (response.status == 204) {
              let it: string = this._itinerary?.type == "L" ? 
                this.dictionary['Nakládka'][this.country] : 
                this.dictionary['Vykládka'][this.country];
              this._alertServ.alert(
                it + ' ' + this.dictionary['JižDokončena'][this.country], 'success', 4000
              );
            }
            else {
              let it: string = this._itinerary?.type == "L" ? 
                this.dictionary['Nakládka'][this.country] : 
                this.dictionary['Vykládka'][this.country];
              this._alertServ.alert(
                it + ' - ' + this.dictionary['Dokončení'][this.country], 'success', 4000
              );
              
              // send event to parent
              if (this._itinerary) {
                this.completed.emit(this._itinerary);
              }
            }
          },
          error: (err) => {
            console.log(err);
            this._alertServ.alert(
              this.dictionary['ChybaPotvrzení'][this.country], 'danger', 4000
            );
          }
        })
      );
    }
  }  
}