import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Itinerary } from 'src/app/model/itinerary.object';
import { Obligation } from 'src/app/model/obligation.object';
import { Order } from 'src/app/model/order.object';
import { AlertService } from 'src/app/service/alert.service';
import { FilesService } from 'src/app/service/files.service';
import { ObligationService } from 'src/app/service/obligation.service';
import { OrderService } from 'src/app/service/order.service';
import { ObligationTools } from 'src/app/tools/obligation-tools';
import { OrderTools } from 'src/app/tools/order-tools';

@Component({
  selector: 'app-ext-itinerary-diary',
  templateUrl: './ext-itinerary-diary.component.html',
  styleUrls: ['./ext-itinerary-diary.component.css']
})
export class ExtItineraryDiaryComponent implements OnInit, OnDestroy {
  
  // TODO attachment logic solved here, not in ext-order component

  private _subscribed: Array<Subscription> = [];

  private _input_token: string | null = null;
  public get input_token(): string | null {
    return this._input_token;
  }

  private _input_obligation_key: number | null = null;
  public get input_obligation_key(): number | null {
    return this._input_obligation_key;
  }
  
  private _input_order: string | null = null;
  public get input_order(): string | null {
    return this._input_order;
  }

  public obligation: Obligation | null = null;
  private _loadingObligation: boolean = false;
  public get loadingObligation(): boolean {
    return this._loadingObligation;
  }
  
  public order: Order | null = null;
  private _loadingOrder: boolean = false;
  public get loadingOrder(): boolean {
    return this._loadingOrder;
  }

  constructor(
    private _obligationServ: ObligationService,
    private _orderServ: OrderService,
    private _alertServ: AlertService,
    private _datePipe: DatePipe,
    private _route: ActivatedRoute,
    private _title: Title
  ) {
  }

  ngOnInit(): void {
    this._title.setTitle('Dispozice');
    this.loadData();
  }
  
  ngOnDestroy() {
    this._subscribed.forEach(
      subsriber => {
        subsriber.unsubscribe();
      }
    );
    this._subscribed = [];
  }

  private loadData(): void {
    this._subscribed.push(
      // solve input query keys
      this._route.queryParams.subscribe(
        params => {
          if (!this._input_token) {
            this._input_token = params['token'] || null;
          }
          if (!this._input_obligation_key) {
            this._input_obligation_key = params['obligation_key'] || null;
          }
          if (!this._input_order) {
            this._input_order = params['order'] || null;
          }

          if (this._input_token && this._input_obligation_key) {
            this.loadObligation();
          }
          else if (this._input_token && this._input_order) {
            this.loadOrder();
          }
        }
      )
    );
  }

  private loadObligation(): void {
    this._loadingObligation = true;
    this._subscribed.push(
      this._obligationServ.getObligation(this._input_token, this._input_obligation_key).subscribe({
        next: (response) => {
          this.obligation = ObligationTools.buildObligation(response);
          this._loadingObligation = false;
          // log only on localhost
          if (!window.location.href.match(/ext2.truckmanager.eu/)) {
            console.log(response);
          }
        },
        error: (err) => {
          console.log(err);
          this._alertServ.alert('Nepodařilo se načíst zakázku.', 'danger', 4000);
          this._loadingObligation = false;
        }
      })
    );
  }
  
  private loadOrder(): void {
    this._loadingOrder = true;
    let token = this._input_token;
    let order = this._input_order;

    this._subscribed.push(
      this._orderServ.getExternalOrder(token, order).subscribe({
        next: (response) => {
          this.order = OrderTools.buildOrder(response);
          this._loadingOrder = false;
          // log only on localhost
          if (!window.location.href.match(/ext2.truckmanager.eu/)) {
            console.log(this.order);
          }
        },
        error: (err) => {
          console.log(err);
          this._alertServ.alert('Nepodařilo se načíst objednávku.', 'danger', 4000);
          this._loadingOrder = false;
        }
      })
    );
  }

  
  /***********************************************/
  /* Uploaded file from itinerary-diary */
  /***********************************************/
  uploadFileEvent(event: any): void {
    if (event) {
      // just reload after added file
      if (this._input_token && this._input_obligation_key) {
        this.loadObligation();
      }
      else if (this._input_token && this._input_order) {
        this.loadOrder();
      }
    }
  }

  
  /***********************************************/
  /* Completing itinerary */
  /***********************************************/
  public itineraryToComplete: Itinerary | null = null;

  completeItineraryShow(it: Itinerary): void {
    if (it) {
      this.itineraryToComplete = it;
      (<any>$('#completeItineraryModal')).modal('show');
    }
  }
  
  completed(it: Itinerary): void {
    if (this.itineraryToComplete) {
      this.itineraryToComplete.completed = true;
      (<any>$('#completeItineraryModal')).modal('hide');
    }
  }


  /***********************************************/
  /* Geolocation features */
  /***********************************************/
  public geolocationError: boolean = false;
  public geolocationString: string = '';

  geolocationErrorEvent(event: any): void {
    if (event == true) {
      this.geolocationError = true;
    }
    else if (event == false) {
      this.geolocationError = false;
    }
  }

  geolocationStringEvent(event: any): void {
    if (event) {
      this.geolocationString += this._datePipe.transform(new Date(), 'dd.MM.yyyy HH:mm:ss') + ' - ';
      this.geolocationString += event;
      this.geolocationString += '<br><br>';
    }
  }

  openGeolocationModal(event: any): void {
    if (event) {
      (<any>$('#geolocationModal')).modal('show');
    }
  }
}
