declare var google: any;

export class GoogleLatLng {

  constructor(
    private _lat: number, 
    private _lng: number
  ) {
  }

  get lat(): number {
    return this._lat;
  }

  get lng(): number {
    return this._lng;
  }

  get createString(): string {
    return '(' + this._lat + ',' + this._lng + ')';
  }

  get googleLtLn(): any {
    if (isNaN(this.lat) || isNaN(this.lng)) {
      console.error('UNDEFINEd', this.lat, this.lng);
    }
    return new google.maps.LatLng(this.lat, this.lng);
  }

  toArray(): Array<number> {
    return [
      this.lat,
      this.lng
    ];
  }

  toString(): string {
    return this.lat + ',' + this.lng;
  }
}
