<div class="container my-4">
  <div class="row">
    <div class="col-12 text-center">
      <h2>Externí aplikace systému TruckManager a TruckAgenda</h2>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12 text-center">
      <h6>
        Veškeré funkce této aplikace jsou platné pouze přes platný odkaz.
      </h6>
    </div>
  </div>
</div>
