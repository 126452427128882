import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultComponent } from './components/default/default.component';
import { ExtItineraryDiaryComponent } from './components/ext-itinerary-diary/ext-itinerary-diary.component';
import { ExtObligationComponent } from './components/ext-obligation/ext-obligation.component';
import { ExtOrderComponent } from './components/ext-order/ext-order.component';

const routes: Routes = [
  { path: '', component: DefaultComponent },
  { path: 'ext-order', component: ExtOrderComponent },
  { path: 'ext-itinerary-diary', component: ExtItineraryDiaryComponent },
  { path: 'ext-obligation', component: ExtObligationComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
