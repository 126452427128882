import { DateTools } from "../tools/date-tools";
import { BookItem } from "./book-item.object";
import { Invoice } from "./invoice.object";
import { Itinerary } from "./itinerary.object";

export class Order {
  private _order_key: number | null = null;
  get order_key(): number | null {
    return this._order_key;
  }
  set order_key(value: number | null) {
    this._order_key = value;
  }

  private _accept_token: string | null = null;
  public get accept_token(): string | null {
    return this._accept_token;
  }
  public set accept_token(value: string | null) {
    this._accept_token = value;
  }
  
  private _accepted_by: string | null = null;
  public get accepted_by(): string | null {
    return this._accepted_by;
  }
  public set accepted_by(value: string | null) {
    this._accepted_by = value;
  }

  private _accepted_from: string | null = null;
  public get accepted_from(): string | null {
    return this._accepted_from;
  }
  public set accepted_from(value: string | null) {
    this._accepted_from = value;
  }
  
  private _accepted_time: string | null = null;
  public get accepted_time(): string | null {
    return this._accepted_time;
  }
  public set accepted_time(value: string | null) {
    this._accepted_time = value;
  }
  
  private _allow_einvoicing: boolean | null = null;
  public get allow_einvoicing(): boolean | null {
    return this._allow_einvoicing;
  }
  public set allow_einvoicing(value: boolean | null) {
    this._allow_einvoicing = value;
  }

  private _company_key: number | null = null;
  public get company_key(): number | null {
    return this._company_key;
  }
  public set company_key(value: number | null) {
    this._company_key = value;
  }
  
  private _contracted_price: number | null = null;
  get contracted_price(): number | null {
    return this._contracted_price;
  }
  set contracted_price(value: number | null) {
    this._contracted_price = value;
  }

  private _contract_terms: string | null = null;
  public get contract_terms(): string | null {
    return this._contract_terms;
  }
  public set contract_terms(value: string | null) {
    this._contract_terms = value;
  }
  
  private _created_time: string | null = null;
  get created_time(): string | null {
    return this._created_time;
  }
  set created_time(value: string | null) {
    this._created_time = value;
    if (value) {
      this._created_time_date = DateTools.isoFix(value);

      // validity = created time + 90 days
      if (this._created_time_date) {
        let time: number = this._created_time_date.getTime() + (90*24*60*60*1000); 
        this._valid_token_date = new Date(time);
      }
    }
  }

  private _created_time_date: Date | null = null;
  public get created_time_date(): Date | null {
    return this._created_time_date;
  }
  public set created_time_date(value: Date | null) {
    this._created_time_date = value;
  }

  private _valid_token_date: Date | null = null;
  public get valid_token_date(): Date | null {
    return this._valid_token_date;
  }
  public set valid_token_date(value: Date | null) {
    this._valid_token_date = value;
  }
  
  private _currency: string | null = null;
  public get currency(): string | null {
    return this._currency;
  }
  public set currency(value: string | null) {
    this._currency = value;
  }

  // delivery_type CHAR(1),      
  // -- typ prepravy - NULL/A = standardni ode dveri ke dverim 
  // 'P' = vyzvednuti (svoz / PickUp)
  // 'D' = doruceni (rozvoz / delivery)
  private _delivery_type: string | null = null;
  public get delivery_type(): string | null {
    return this._delivery_type;
  }
  public set delivery_type(value: string | null) {
    this._delivery_type = value;
  }
  
  private _driver_name: string | null = null;
  public get driver_name(): string | null {
    return this._driver_name;
  }
  public set driver_name(value: string | null) {
    this._driver_name = value;
  }

  private _driver_phone: string | null = null;
  public get driver_phone(): string | null {
    return this._driver_phone;
  }
  public set driver_phone(value: string | null) {
    this._driver_phone = value;
  }

  private _email_msg_ids: Array<string | null> = [];
  public get email_msg_ids(): Array<string | null> {
    return this._email_msg_ids;
  }
  public set email_msg_ids(value: Array<string | null>) {
    this._email_msg_ids = value;
  }

  private _favourite: boolean | null = null;
  public get favourite(): boolean | null {
    return this._favourite;
  }
  public set favourite(value: boolean | null) {
    this._favourite = value;
  }
  
  private _invoices: Array<Invoice> = [];
  public get invoices(): Array<Invoice> {
    return this._invoices;
  }
  public set invoices(value: Array<Invoice>) {
    this._invoices = value;
  }

  private _number: number | null = null;
  public get number(): number | null {
    return this._number;
  }
  public set number(value: number | null) {
    this._number = value;
  }

  // Obligation
  private _obligations: Array<any> = [];
  public get obligations(): Array<any> {
    return this._obligations;
  }
  public set obligations(value: Array<any>) {
    this._obligations = value;
    if (this._obligations && this._obligations.length) {
      // reinit itinerary
      this._itinerary = [];
      this._obligations.forEach(
        o => {
          if (o.itinerary && o.itinerary.length) {
            if (this._delivery_type == 'P') {
              if (o.piece_shipment) {
                // warehouse - pick up / collection order
                this._itinerary = this._itinerary.concat(o.itinerary_warehouse_collection);
              }
              else if (o.transshipment) {
                // transshipment - pick up / collection order
                this._itinerary = this._itinerary.concat(o.itinerary_transshipment_collection);
              }
            }
            else if (this._delivery_type == 'D') {
              if (o.piece_shipment) {
                // warehouse - delivery order
                this._itinerary = this._itinerary.concat(o.itinerary_warehouse_delivery);
              }
              else if (o.transshipment) {
                // transshipment - pick up / collection order
                this._itinerary = this._itinerary.concat(o.itinerary_transshipment_delivery);
              }
            }
            else {
              // standard order
              this._itinerary = this._itinerary.concat(o.itinerary);
            }
          }
        }
      );
      this._itinerary.sort(
        (a, b) => (a.arrival_time && b.arrival_time && a.arrival_time > b.arrival_time) ? 1 : -1
      );
    }
  }

  // custom
  private _itinerary: Array<Itinerary> = [];
  public get itinerary(): Array<Itinerary> {
    return this._itinerary;
  }
  public set itinerary(value: Array<Itinerary>) {
    this._itinerary = value;
  }

  private _order_comp_book_key: number | null = null;
  public get order_comp_book_key(): number | null {
    return this._order_comp_book_key;
  }
  public set order_comp_book_key(value: number | null) {
    this._order_comp_book_key = value;
  }

  private _order_comp_book: BookItem | null = null;
  get order_comp_book(): BookItem | null {
    return this._order_comp_book;
  }
  set order_comp_book(value: BookItem | null) {
    this._order_comp_book = value;
  }
  
  private _order_comp_name_long: string | null = null;
  get order_comp_name_long(): string | null {
    return this._order_comp_name_long;
  }
  set order_comp_name_long(value: string | null) {
    this._order_comp_name_long = value;
  }

  private _order_contact: any;
  public get order_contact(): any {
    return this._order_contact;
  }
  public set order_contact(value: any) {
    this._order_contact = value;
  }

  private _order_phone: any;
  public get order_phone(): any {
    return this._order_phone;
  }
  public set order_phone(value: any) {
    this._order_phone = value;
  }
  
  private _order_email: any;
  public get order_email(): any {
    return this._order_email;
  }
  public set order_email(value: any) {
    this._order_email = value;
  }

  private _person_key: number | null = null;
  public get person_key(): number | null {
    return this._person_key;
  }
  public set person_key(value: number | null) {
    this._person_key = value;
    // 0 cant be saved in api request
    if (this._person_key == 0) this._person_key = null;
  }

  private _person: any;
  public get person(): any {
    return this._person;
  }
  public set person(value: any) {
    this._person = value;
  }

  private _order_number_standard: string | null = null;
  get order_number_standard(): string | null {
    return this._order_number_standard;
  }
  set order_number_standard(value: string | null) {
    this._order_number_standard = value;
  }
  
  // custom property for detecting if order has been marked/checked
  private _marked: boolean | null = null;
  public get marked(): boolean | null {
    return this._marked;
  }
  public set marked(value: boolean | null) {
    this._marked = value;
  }

  private _invoice_maturity: number | null = null;
  public get invoice_maturity(): number | null {
    return this._invoice_maturity;
  }
  public set invoice_maturity(value: number | null) {
    this._invoice_maturity = value;
  }

  private _note: string | null = null;
  public get note(): string | null {
    return this._note;
  }
  public set note(value: string | null) {
    this._note = value;
  }
  
  private _note_price: string | null = null;
  public get note_price(): string | null {
    return this._note_price;
  }
  public set note_price(value: string | null) {
    this._note_price = value;
  }

  // custom property for binding obligation notes to textarea
  private _note_obligations: string | null = null;
  public get note_obligations(): string | null {
    return this._note_obligations;
  }
  public set note_obligations(value: string | null) {
    this._note_obligations = value;
  }
  
  private _series: number | null = null;
  public get series(): number | null {
    return this._series;
  }
  public set series(value: number | null) {
    this._series = value;
  }

  private _spz: string | null = null;
  public get spz(): string | null {
    return this._spz;
  }
  public set spz(value: string | null) {
    this._spz = value;
  }

  private _car_type: string | null = null;
  public get car_type(): string | null {
    return this._car_type;
  }
  public set car_type(value: string | null) {
    this._car_type = value;
  }
  
  private _spz_trailer: string | null = null;
  public get spz_trailer(): string | null {
    return this._spz_trailer;
  }
  public set spz_trailer(value: string | null) {
    this._spz_trailer = value;
  }
  
  private _status: string | null = null;
  public get status(): string | null {
    return this._status;
  }
  public set status(value: string | null) {
    this._status = value;
  }

  private _updated_by: string | null = null;
  public get updated_by(): string | null {
    return this._updated_by;
  }
  public set updated_by(value: string | null) {
    this._updated_by = value;
  }
  
  private _updated_time: string | null = null;
  public get updated_time(): string | null {
    return this._updated_time;
  }
  public set updated_time(value: string | null) {
    this._updated_time = value;
  }

  private _year: number | null = new Date().getFullYear();
  public get year(): number | null {
    return this._year;
  }
  public set year(value: number | null) {
    this._year = value;
  }

  public get orderNumberFormatted(): string | null {
    if (!this._order_number_standard) {
      return '';
    }
    return 'OB' + this._order_number_standard;
  }
  
  private _previewPDF: any;
  public get previewPDF(): any {
    return this._previewPDF;
  }
  public set previewPDF(value: any) {
    this._previewPDF = value;
  }



  
  public get apiObject(): any {
    return {
      contract_terms: this._contract_terms,
      contracted_price: this._contracted_price,
      currency: this._currency,
      driver_name: this._driver_name,
      driver_phone: this._driver_phone,
      delivery_type: this.delivery_type,
      favourite: this._favourite,
      note: this._note,
      note_price: this._note_price,
      order_comp_book_key: this._order_comp_book_key,
      order_comp_name_long: this._order_comp_name_long,
      order_contact: this._order_contact,
      order_email: this._order_email,
      order_phone: this._order_phone,
      person_key: this._person_key,
      series: this._series,
      spz: this._spz,
      spz_trailer: this._spz_trailer,
      car_type: this._car_type,
      status: this._status,
      year: this._year
    };
  }
}
