import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from '@angular/router';
import { Options } from '@angular-slider/ngx-slider';
import { Subscription } from 'rxjs';

import { Itinerary } from 'src/app/model/itinerary.object';
import { Obligation } from 'src/app/model/obligation.object';
import { AlertService } from 'src/app/service/alert.service';
import { LocalJsonService } from 'src/app/service/local-json.service';
import { ObligationService } from 'src/app/service/obligation.service';
import { DateTools } from 'src/app/tools/date-tools';
import { ObligationTools } from 'src/app/tools/obligation-tools';
import { Numbers } from 'src/app/tools/numbers';
import { GoogleMapMarker } from 'src/app/model/google-map-marker.object';
import { GoogleLatLng } from 'src/app/model/google-lat-lng.object';

declare var google: any;

@Component({
  selector: 'app-ext-obligation',
  templateUrl: './ext-obligation.component.html',
  styleUrls: ['./ext-obligation.component.css'],
  providers: [
    ObligationService,
    LocalJsonService
  ]
})
export class ExtObligationComponent implements OnInit {

  private _subscribed: Array<Subscription> = [];

  private _input_book_key: string | null = null;
  
  private _input_token: string | null = null;
  public get input_token(): string | null {
    return this._input_token;
  }

  public obligation: Obligation = new Obligation();
  public itinerary: Array<Itinerary> = [];
  public itineraryMap: Itinerary | null = null;

  public company: any = null;
  private _loadingCompany: boolean = false;
  public get loadingCompany(): boolean {
    return this._loadingCompany;
  }
  
  public bookItem: any = null;
  private _loadingBookItem: boolean = false;
  public get loadingBookItem(): boolean {
    return this._loadingBookItem;
  }

  public logo: any = null;
  private _loadingLogo: boolean = false;
  public get loadingLogo(): boolean {
    return this._loadingLogo;
  }
  
  public settings: any = null;
  public borderColor: string = '#0d6efd';
  private _loadingSettings: boolean = false;
  public get loadingSettings(): boolean {
    return this._loadingSettings;
  }

  public obligations: Array<Obligation> = [];
  private _loadingObligations: boolean = false;
  public get loadingObligations(): boolean {
    return this._loadingObligations;
  }

  public dictionary: any = null;
  public country: string = 'CZ';

  constructor(
    private _obligationServ: ObligationService,
    private _localJsonServ: LocalJsonService,
    private _alertServ: AlertService,
    private _route: ActivatedRoute,
    private _datePipe: DatePipe,
    private _title: Title,
    private _ref: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    let today: Date = new Date();
    // init default obligation
    this.obligation.series = 1;
    this.obligation.subnumber = 1;
    this.obligation.year = today.getFullYear();
    this.obligation.status = 'E';
    this.obligation.order_number = this._datePipe.transform(today, 'dd-MM-yyyy-HH:mm');

    let it: Itinerary = new Itinerary();
    it.position = 1;
    it.type = 'L';
    this.initDate(it);
    this.itinerary.push(it);
    
    let it2: Itinerary = new Itinerary();
    it2.position = 2;
    it2.type = 'U';
    this.initDate(it2);
    this.itinerary.push(it2);
    
    this.itinerary.forEach(
      it => {
        this.initAutocompleter(it);
      }
    );

    this._title.setTitle('net-Objednávka');
    this.loadData();
  }

  ngOnDestroy() {
    this._subscribed.forEach(
      subsriber => {
        subsriber.unsubscribe();
      }
    );
    this._subscribed = [];
  }

  ngAfterViewChecked() {
    // managing google autocompleter inputs
    this.itinerary.forEach(
      it => {
        this.initAutocompleter(it);
      }
    );
  }


  loadData(): void {
    this._subscribed.push(
      // solve input query keys
      this._route.queryParams.subscribe(
        params => {
          if (!this._input_book_key) {
            this._input_book_key = params['book_key'] || null;
          }
          if (!this._input_token) {
            this._input_token = params['token'] || null;
          }
          if (this._input_token) {
            this.loadObligationsHistory();
            this.loadCompany();
            this.loadBookItem();
            this.loadSettingsDocs();
            this.loadLogo();
          }
        }
      ),
      this._localJsonServ.getObligationsDictionary().subscribe({
        next: (response) => {
          this.dictionary = response;
        },
        error: (err) => {
          console.log(err);
          this._alertServ.alert('Nepodařilo se načíst slovník.', 'danger', 4000);
        }
      })
    );
  }

  
  get loading(): boolean {
    return (
      this._loadingCompany || 
      this._loadingBookItem || 
      this._loadingSettings || 
      this._loadingLogo
    );
  }
  
  get validTokenObligation(): boolean {
    return this._input_token != null && this._input_book_key != null;
  }

  get validItinerary(): boolean {
    let valid: boolean = true;
    this.itinerary.forEach(
      it => {
        if (!it.arrival_time_custom || !it.address || !it.gps_coord) {
          valid = false;
        }
      }
    );
    return valid;
  }

  private loadCompany(): void {
    this._loadingCompany = true;
    this._subscribed.push(
      this._obligationServ.getCompany(this._input_token).subscribe({
        next: (response) => {
          this.company = response;
          if (this.company && this.company.company) {
            this._title.setTitle(this.company.company);
          }
          this._loadingCompany = false;
        },
        error: (err) => {
          console.log(err);
          this._alertServ.alert(this.dictionary['CompanyGetError'][this.country], 'danger', 4000);
          this._loadingCompany = false;
        }
      })
    );
  }

  private loadBookItem(): void {
    this._loadingBookItem = true;
    this._subscribed.push(
      this._obligationServ.getBookItem(this._input_token).subscribe({
        next: (response) => {
          this.bookItem = response;
          if (this.bookItem && this.bookItem.country) {
            this.country = this.bookItem.country;
            if (!['CZ', 'SK', 'EN', 'DE', 'IT', 'ES', 'PL'].includes(this.country)) {
              // out of our dictionary - use EN
              this.country = 'EN';
            }
          }
          this._loadingBookItem = false;
        },
        error: (err) => {
          console.log(err);
          this._alertServ.alert(this.dictionary['CompanyGetError'][this.country], 'danger', 4000);
          this._loadingBookItem = false;
        }
      })
    );
  }
  
  private loadSettingsDocs(): void {
    this._loadingSettings = true;
    this._subscribed.push(
      this._obligationServ.getSettingsDocs(this._input_token).subscribe({
        next: (response) => {
          this.settings = response;
          if (this.settings.presets && this.settings.presets.invoice_color2) {
            this.borderColor = this.settings.presets.invoice_color2;
          }
          this._loadingSettings = false;
        },
        error: (err) => {
          console.log(err);
          this._alertServ.alert(this.dictionary['SettingsGetError'][this.country], 'danger', 4000);
          this._loadingSettings = false;
        }
      })
    );
  }

  private loadLogo(): void {
    this._loadingLogo = true;
    this._subscribed.push(
      this._obligationServ.getLogo(this._input_token).subscribe({
        next: (response) => {
          let newBlob: any = new Blob([response], { type: response.type });
          this.logo = {
            content: URL.createObjectURL(newBlob),
            type: response.type,
            blob: newBlob
          };
          this._loadingLogo = false;
        },
        error: (err) => {
          console.log(err);
          this._alertServ.alert(this.dictionary['LogoGetError'][this.country], 'danger', 4000);
          this._loadingLogo = false;
        }
      })
    );
  }

  
  /*****************************************************/
  /* Get obligations history */
  /*****************************************************/
  // our default pagination number is 10 obligation for page
  private _PAGE_MAX_RECORDS: number = 10;
  public currentPage: number = 0;

  public filterObj: any = {};
  public filterItinAddr: string | null = null;

  hasNextPage(): boolean {
    return this.obligations.length >= this._PAGE_MAX_RECORDS;
  }

  hasPreviousPage(): boolean {
    return this.currentPage > 0;
  }

  filterChange(): void {
    this.filterObj = {};
    if (this.filterItinAddr) {
      this.filterObj['itin_addr'] = this.filterItinAddr;
    }
    this.currentPage = 0;
    this.loadObligationsHistory();
  }

  filterSortObligations(pageIncrement: number): void {
    this.currentPage += pageIncrement;
    this.loadObligationsHistory();
  }

  loadObligationsHistory(): void {
    this._loadingObligations = true;
    this._subscribed.push(
      this._obligationServ.getAllObligations(this._input_token, this.filterObj, this.currentPage, this._PAGE_MAX_RECORDS).subscribe({
        next: (response) => {
          if (response) {
            this.obligations = ObligationTools.buildObligationsFromData(response);
            // set series according history of obligations
            if (this.obligations.length) {
              this.obligation.series = this.obligations[0].series;
            }
          }
          this._loadingObligations = false;
        },
        error: (err) => {
          console.log(err);
          this._loadingObligations = false;
        }
      })
    );
  }


  /*****************************************************/
  /* Duplicate obligation */
  /*****************************************************/
  duplicate(o: Obligation): void {
    let today: Date = new Date();
    // init basic info of obligation
    this.obligation = new Obligation();
    this.obligation.order_number = this._datePipe.transform(today, 'dd-MM-yyyy-HH:mm');
    this.obligation.series = o.series;
    this.obligation.code = o.code;
    this.obligation.note = o.note;
    this.obligation.route_length = o.route_length;
    this.obligation.weight = o.weight;

    // init itinerary
    if (o.itinerary && o.itinerary.length) {
      this.itinerary = [];
      // compute time aligment (duplicit itinerary will be starting tomorrow)
      let timeAligment: number = 0;
      
      // find first nakl/vykl (prejezd, tranzit, tankovani neplanujeme arrival_time)
      let firstIt: Itinerary = o.itinerary[0];
      if (firstIt && firstIt.arrival_time_date) {
        let obligationStart: Date = firstIt.arrival_time_date;
        let today: Date = new Date();
        today.setHours(obligationStart.getHours());
        today.setMinutes(obligationStart.getMinutes());
        timeAligment = today.getTime() + 24*60*60*1000 - obligationStart.getTime();
      }
      // loop itinerary and copy properties
      o.itinerary.forEach(
        it => {
          let new_it: Itinerary = new Itinerary();
          new_it.type = it.type;
          new_it.position = it.position;
          new_it.address = it.address;
          new_it.place_city = it.place_city;
          new_it.place_country = it.place_country;
          new_it.place_name = it.place_name;
          new_it.place_street = it.place_street;
          new_it.place_zip = it.place_zip;
          new_it.loading_time_limit = it.loading_time_limit;
          new_it.weight = it.weight;
          new_it.ware_pcs = it.ware_pcs;
          new_it.ware_type = it.ware_type;
          new_it.note = it.note;
          new_it.work_day_begin = it.work_day_begin;
          new_it.work_day_begin_minutes = it.work_day_begin_minutes;
          new_it.work_day_end = it.work_day_end;
          new_it.work_day_end_minutes = it.work_day_end_minutes;
          new_it.route_part_length = it.route_part_length;
          // initializing time from first itinerary set tomorrow
          new_it.arrival_time = it.arrival_time;
          if (new_it.arrival_time_date) {
            new_it.arrival_time_date = new Date(new_it.arrival_time_date.getTime() + timeAligment);
            // format for datetime-local
            new_it.arrival_time_custom = this._datePipe.transform(
              new_it.arrival_time_date, 'yyyy-MM-ddTHH:mm'
            );
          }
          // new marker
          if (it.gps_coord) {
            new_it.gps_coord = it.gps_coord;
            new_it.marker = new GoogleMapMarker();
            let latLng: Array<string> = it.gps_coord.replace(/\(|\)/g, '').split(',');

            if (latLng && latLng.length == 2) {
              new_it.marker.position = new GoogleLatLng(parseFloat(latLng[0]), parseFloat(latLng[1]));
              new_it.marker.draggable = true;
              new_it.marker.setData('type', 2);
              new_it.marker.setData('id', Math.random().toString(36).substring(7));
              if (new_it.type == 'L') {
                new_it.marker.icon = '../../../assets/img/loading.svg';
              }
              else if (new_it.type == 'U') {
                new_it.marker.icon = '../../../assets/img/unloading.svg';
              }
              else {
                new_it.marker.icon = 'http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=' + it.position + '|56FE62|000000';
              }
              // add listener for dragging
              new_it.marker.getGoogleMarker().addListener('drag', (event: any) => {
                if (event && event.latLng) {
                  // dragging gps coord
                  it.gps_coord = '(' + Numbers.round(event.latLng.lat(), 6).toString() + ',' 
                              + Numbers.round(event.latLng.lng(), 6).toString() + ')';
                }
              });
            }
          }
          // save itinerary
          this.itinerary.push(new_it);
        }
      );
    }

    // scroll to top of page
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }


  /*****************************************************/
  /* Creating new obligation */
  /*****************************************************/
  createObligation(): void {
    let data: any = {
      series: this.obligation.series,
      subnumber: this.obligation.subnumber,
      year: this.obligation.year,
      order_number: this.obligation.order_number,
      status: this.obligation.status,
      code: this.obligation.code,
      note: this.obligation.note,
      route_length: this.obligation.route_length,
      weight: this.obligation.weight
    }

    this._subscribed.push(
      this._obligationServ.createObligation(this._input_token, data).subscribe({
        next: (response) => {
          // log only on localhost
          if (!window.location.href.match(/ext2.truckmanager.eu/)) {
            console.log(response);
          }
          this._alertServ.alert(this.dictionary['ObligationPostOk'][this.country], 'success', 4000);

          let it_requests_finished: number = 0;
          if (response.obligation_key) {
            this.itinerary.forEach(
              it => {
                let it_data: any = {};
                it_data.type = it.type;
                it_data.position = it.position;
                it_data.address = it.address;
                it_data.place_city = it.place_city;
                it_data.place_country = it.place_country;
                it_data.place_name = it.place_name;
                it_data.place_street = it.place_street;
                it_data.place_zip = it.place_zip;
                it_data.gps_coord = it.gps_coord;
                it_data.loading_time_limit = it.loading_time_limit;
                it_data.weight = it.weight;
                it_data.ware_pcs = it.ware_pcs;
                it_data.ware_type = it.ware_type;
                it_data.note = it.note;
                it_data.work_day_begin = it.work_day_begin;
                it_data.work_day_end = it.work_day_end;
                it_data.route_part_length = it.route_part_length;
                if (it.arrival_time_custom) {
                  it_data.arrival_time = DateTools.toIsoWithoutMilisec(new Date(it.arrival_time_custom));
                }

                this._subscribed.push(
                  this._obligationServ.createItinerary(response.obligation_key, this._input_token, it_data).subscribe({
                    next: (it_response) => {
                      // log only on localhost
                      if (!window.location.href.match(/ext2.truckmanager.eu/)) {
                        console.log(it_response);
                      }
                      it_requests_finished += 1;
                      if (it_requests_finished == this.itinerary.length) {
                        // send email notification 
                        this.sendConfirmationEmail(response.obligation_key);
                        // reload obligations history with currently created obligation
                        this.loadObligationsHistory();
                      }
                    },
                    error: (err) => {
                      console.log(err);
                      this._alertServ.alert(
                        this.dictionary['ItineraryPostError'][this.country], 'danger', 4000
                      );
                    }
                  })
                );
              }
            );
          }
        },
        error: (err) => {
          console.log(err);
          this._alertServ.alert(
            this.dictionary['ObligationPostError'][this.country], 'danger', 4000
          );
        }
      })
    );
  }

  // email sending right after creating obligation and all itinerary
  sendConfirmationEmail(obligation_key: number | null): void {
    this._subscribed.push(
      this._obligationServ.sendConfirmationEmail(
        this._input_token, 
        obligation_key, 
        this.country.toLowerCase()
      ).subscribe({
        next: (response) => {
          // log only on localhost
          if (!window.location.href.match(/ext2.truckmanager.eu/)) {
            console.log(response);
          }
        },
        error: (err) => {
          console.log(err);
        }
      })
    );
  }


  /****************************************************/
  /* Itinerary managing */
  /****************************************************/
  // method for default initialization of date on focus - today midnight
  initDate(itinerary: Itinerary): void {
    if (!itinerary.arrival_time_custom) {
      // init also default strings for work_day_begin/end
      itinerary.work_day_begin = '07:00:00';
      itinerary.work_day_end = '16:00:00';

      let tomorrow: Date = new Date(Date.now() + 24*60*60*1000);
      tomorrow.setHours(7);
      tomorrow.setMinutes(0);
      itinerary.arrival_time_date = tomorrow;
      itinerary.arrival_time_custom = this._datePipe.transform(tomorrow, 'yyyy-MM-ddTHH:mm');
    }
  }

  // method for adding new itinerary (down in list of itinerary)
  addDefaultItinerary(): void {
    let it: Itinerary = new Itinerary();
    it.type = 'U';
    this.initDate(it);
    if (this.itinerary.length) {
      let last = this.itinerary[this.itinerary.length - 1];
      if (last && last.position) {
        it.position = last.position + 1;
      }
    }
    else {
      it.position = 1;
    }
    
    this.itinerary.forEach(
      it => {
        this.initAutocompleter(it);
      }
    );

    this.itinerary.push(it);
  }

  // method for removing the itinerary
  removeItinerary(position: number | null): void {
    if (position) {
      this.itinerary.splice(position - 1, 1); 
      // reinit itinerary positions
      let index: number = 1;
      this.itinerary.forEach(
        it => {
          it.position = index;
          index++;
        }
      );
      // computing distance
      this.computeRouteLength();
    }
  }

  // method for decrement position of the itinerary(gets upper in list)
  positionUp(position: number | null, top: HTMLElement, down: HTMLElement): void {
    if (position && position > 1) {
      this.itinerary[position-1].position = position - 1;
      this.itinerary[position-2].position = position;

      // sort it again according to position
      this.itinerary.sort((a, b) => (a.position && b.position && a.position > b.position) ? 1 : -1);

      // computing distance
      this.computeRouteLength();

      // scrolling smoothly
      if (position < 4) {
        top.scrollIntoView({behavior:"smooth"});
      }
      else {
        down.scrollIntoView({behavior:"smooth", block: "end"});
      }
    }
  }

  // method for increment position of the itinerary(gets lower in list)
  positionDown(position: number | null, top: HTMLElement, down: HTMLElement): void {
    if (position && position < this.itinerary.length) {
      this.itinerary[position-1].position = position + 1;
      this.itinerary[position].position = position;

      // sort it again according to position
      this.itinerary.sort((a, b) => (a.position && b.position && a.position > b.position) ? 1 : -1);

      // computing distance
      this.computeRouteLength();

      // scrolling smoothly
      if (position < 3) {
        top.scrollIntoView({behavior:"smooth"});
      }
      else {
        down.scrollIntoView({behavior:"smooth", block: "end"});
      }
    }
  }

  /* ngx-slider */
  public options: Options = {
    floor: 0,
    step: 15,
    ceil: 1439,
    animate: false,
    translate: (value: number): string => {
      if (value != 0 && !value) return '';

      let hours: number = Math.floor(value / 60);
      let hoursString: string = (hours < 10) ? ('0' + hours.toString()) : hours.toString(); 
      let minutes: number = value % 60;
      let minutesString: string = (minutes < 10) ? ('0' + minutes.toString()) : minutes.toString();
      return hoursString + ':' + minutesString;
    }
  };

  // work_day_begin - work_day_end
  changeWorkDayBegin(itinerary: Itinerary): void {
    let hours: number = Math.floor(itinerary.work_day_begin_minutes / 60);
    let hoursString: string = (hours < 10) ? ('0' + hours.toString()) : hours.toString(); 
    let minutes: number = itinerary.work_day_begin_minutes % 60;
    let minutesString: string = (minutes < 10) ? ('0' + minutes.toString()) : minutes.toString(); 

    itinerary.work_day_begin = hoursString + ':' + minutesString + ':00';
  }
  
  changeWorkDayEnd(itinerary: Itinerary) {
    let hours: number = Math.floor(itinerary.work_day_end_minutes / 60);
    let hoursString: string = (hours < 10) ? ('0' + hours.toString()) : hours.toString(); 
    let minutes: number = itinerary.work_day_end_minutes % 60;
    let minutesString: string = (minutes < 10) ? ('0' + minutes.toString()) : minutes.toString(); 

    itinerary.work_day_end = hoursString + ':' + minutesString + ':00';
  }

  // new method for saving creating autocomplete instances in existing obligations
  initAutocompleter(it: Itinerary): void {
    // if (this.obligationEdit && !it.googleAutocompleter) {
    if (!it.googleAutocompleter) {    
      let input = document.getElementById('google-input' + it.position);
      if (input instanceof HTMLInputElement) {
        this.createGoogleAutocomplete(input, it);
      }
    }   
  }

  // method for creating google autocompleter for each itinerary with listener
  createGoogleAutocomplete(input: any, it: Itinerary): void {
    // only 3 basic fields
    const options: any = {
      fields: ["formatted_address", "geometry", "name", "address_component"]
    };

    it.googleAutocompleter = new google.maps.places.Autocomplete(input, options);
    // set listener
    it.googleAutocompleter.addListener('place_changed', () => {
      let place = it.googleAutocompleter.getPlace();
      if (!place || !place.geometry) {
        return;
      }
      // init address
      this.initAddressComponents(it, place);
      // save gps_coord
      it.gps_coord = '(' + Numbers.round(place.geometry.location.lat(), 6).toString() + ','
        + Numbers.round(place.geometry.location.lng(), 6).toString() + ')';

      it.marker = new GoogleMapMarker();
      it.marker.position = place.geometry.location;
      it.marker.draggable = true;
      it.marker.setData('type', 2);
      it.marker.setData('id', Math.random().toString(36).substring(7));
      if (it.type == 'L') {
        it.marker.icon = '../../../assets/img/loading.svg';
      }
      else if (it.type == 'U') {
        it.marker.icon = '../../../assets/img/unloading.svg';
      }
      else {
        it.marker.icon = 'http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=' + it.position + '|56FE62|000000';
      }
      // add listener for dragging
      it.marker.getGoogleMarker().addListener('drag', (event: any) => {
        if (event && event.latLng) {
          // dragging gps coord
          it.gps_coord = '(' + Numbers.round(event.latLng.lat(), 6).toString() + ',' 
                      + Numbers.round(event.latLng.lng(), 6).toString() + ')';
        }
      });
      
      // computing distance
      this.computeRouteLength();
    });
  }

  // initializing place components from googleMapsApi response
  // using address_components array with types (route, street_number,...)
  // components have attributes "long_name" and "short_name"
  initAddressComponents(it: Itinerary, place: any): void {
    if (it && place) {
      let addr_components: Array<any> = place.address_components;
      let name: string = place.name;

      if (addr_components && addr_components.length) {
        // street quite complicated types
        let street = addr_components.find(
          c => c.types && (c.types.includes('route') || c.types.includes('neighborhood'))
        );
        // street_number quite complicated types
        let street_number = addr_components.find(
          c => c.types && (c.types.includes('street_number') || c.types.includes('premise'))
        );
        // city quite complicated types
        let city = addr_components.find(
          c => c.types && (
            c.types.includes('locality') || 
            c.types.includes('sublocality') || 
            c.types.includes('postal_town')
          )
        );
        let country = addr_components.find(c => c.types && c.types.includes('country'));
        let zip = addr_components.find(c => c.types && c.types.includes('postal_code'));

        if (street) {
          it.place_street = street.long_name;
          if (street_number) {
            it.place_street += ' ' + street_number.long_name;
          }
        }
        if (city) {
          it.place_city = city.long_name;
        }
        if (country) {
          it.place_country = country.short_name;
        }
        if (zip) {
          it.place_zip = zip.short_name;
          // remove white spaces
          if (it.place_zip) {
            it.place_zip = it.place_zip.replace(/\s/g, '');
            if (it.place_zip.length > 6) {
              it.place_zip = it.place_zip.substring(0,6);
            }
          }
        }

        if (name) {
          it.place_name = name;
        }
        else if (it.place_street && it.place_city && it.place_zip && it.place_country) {
          it.place_name = it.place_street + ', ' + it.place_city + ', ' + it.place_zip;
          it.place_name += ', ' + it.place_country;
        }

        // init also address from components (used to be place.formatted_address)
        // now default is custom address composing
        if (name && it.place_street && it.place_city && it.place_zip && it.place_country) {
          it.address = name + ', ' + it.place_street + ', ' + it.place_city + ' ';
          it.address += it.place_zip + ', ' + it.place_country;
        }
        else if (name) {
          it.address = name + ', ' + place.formatted_address;
        }
        else {
          it.address = place.formatted_address;
        }
      }
    }
  }

  // set total weight of obligation according to itinerary (type U - unloading)
  setObligationWeight(): void {
    let weight: number = 0;
    this.itinerary.forEach(
      it => {
        if (it.weight && it.type == 'U') {
          weight += it.weight;
        }
      }
    );
    if (!weight) {
      // compute it from loading itinerary
      this.itinerary.forEach(
        it => {
          if (it.weight && it.type == 'L') {
            weight += it.weight;
          }
        }
      );
    }

    if (weight != this.obligation.weight) {
      this.obligation.weight = weight;
    }
  }

  private _computingDirections: boolean = false;
  public get computingDirections(): boolean {
    return this._computingDirections;
  }

  // compute route_part_lengths using google directions service
  private computeRouteLength(): void {
    let direction_origin: any = null;
    let direction_dest: any = null;
    let direction_waypoints: Array<any> = [];

    let allMarkersValid: boolean = true;

    this.itinerary.forEach(
      (it: Itinerary, index) => {
        if (it.gps_coord && it.marker) {
          // add waypoints for direction
          if (it.marker.position) {
            if (index == 0) {
              direction_origin = { location: it.marker.position };
            }
            else if (index == this.itinerary.length - 1) {
              direction_dest = { location: it.marker.position };
            }
            else {
              // push it waypoints array
              direction_waypoints.push( { location: it.marker.position } );
            }
          }
        }
        else {
          allMarkersValid = false;
        }
      }
    );

    if (!allMarkersValid) {
      return;
    }

    // init request body
    let request = {
      origin: direction_origin.location,
      destination: direction_dest.location,
      waypoints: direction_waypoints,
      travelMode: google.maps.DirectionsTravelMode.DRIVING
    };
    
    // set loading flag
    this._computingDirections = true;

    let directionsService = new google.maps.DirectionsService();
    directionsService.route(
      request,
      (response: any, status: any) => {
        if (status === google.maps.DirectionsStatus.OK) {
          // console.log(response);
          if (response.routes) {
            // compute route_length and itinerary route_length_parts
            let distance = 0;
            response.routes[0].legs.forEach(
              (leg: any, index: number) => {
                // leg is part between two directions waypoints
                let route_part_length = Math.round(leg.distance.value / 1000);
                // condition should be always passed (for sure)
                if (this.itinerary[index + 1]) {
                  this.itinerary[index + 1].route_part_length = route_part_length;
                }
                // increment total route_length
                distance += route_part_length;
              }
            );
            this.obligation.route_length = distance;
          }
          // using also our time estimation
          this.autocomputeTimeEstimation();
          // unset loading flag
          this._computingDirections = false;
          this._ref.detectChanges();
        }
      }
    );
  }

  // method for invoking computation of time estimation
  autocomputeTimeEstimation(indexFrom: number = 0): void {
    this.itinerary.forEach(
      (it: Itinerary, index: number) => {
        if (index >= indexFrom && this.itinerary[index + 1]) {
          let itinerary1: Itinerary = this.itinerary[index];
          let itinerary2: Itinerary = this.itinerary[index + 1];

          let route_part_length: number = 0;
          if (itinerary2.route_part_length || itinerary2.route_part_length == 0) {
            route_part_length = Math.round(itinerary2.route_part_length);
          }

          if (itinerary1.arrival_time_custom && (route_part_length || route_part_length == 0)) {
            let prevTime: number = new Date(itinerary1.arrival_time_custom).getTime();
            let addTime: number = prevTime;
            
            // loading/unloading time limit
            if (itinerary2.type == 'L' || itinerary2.type == 'U') {
              if (itinerary2.loading_time_limit) {
                addTime += itinerary2.loading_time_limit * 60 * 1000;
              }
              else {
                // default 50 min
                addTime += 50 * 60 * 1000;
              }
            }

            // route_part_length / 55km/h ==~ approximate time
            if (route_part_length || route_part_length == 0) {
              // hours to milisecs
              addTime += (route_part_length / 55) * 60 * 60 * 1000;
            }
            // optimalize arrival_time 
            addTime = this.optimalizeSuggestedTime(addTime, itinerary2);

            let newArrivalTime: Date = new Date(addTime);
            // itinerary2.arrival_time = newArrivalTime;
            itinerary2.arrival_time_custom = this._datePipe.transform(newArrivalTime, 'yyyy-MM-ddTHH:mm');
          }
        }
      }
    );
  }


  // method for optimalizing suggested arrival time (same as in dispatcher board)
  // work_day after 16:00 -> next work_day 07:00
  // weekend -> next monday 07:00
  optimalizeSuggestedTime(miliseconds: number, itinerary: Itinerary): number {
    let result: Date = new Date(miliseconds);

    // TODO work_day hodiny jak desetinna cisla..
    let day_begin: number = 7;
    if (itinerary && itinerary.work_day_begin_minutes) {
      day_begin = Math.round(itinerary.work_day_begin_minutes / 60);
    }
    let day_end: number = 16;
    if (itinerary && itinerary.work_day_end_minutes) {
      day_end = Math.round(itinerary.work_day_end_minutes / 60);
    }

    // optimalize next day only for Loadings and Unloadings
    if (itinerary && (itinerary.type == 'L' || itinerary.type == 'U')) {
      if (result.getDay() == 6) {
        // saturday -> monday day_begin
        result.setDate(result.getDate() + 2);
        result.setHours(day_begin, 0, 0, 0);
      }
      else if (result.getDay() == 0) {
        // sunday -> monday day_begin
        result.setDate(result.getDate() + 1);
        result.setHours(day_begin, 0, 0, 0);
      }
      else if (result.getDay() == 5 && result.getHours() > day_end) {
        // friday after day_end -> monday day_begin
        result.setDate(result.getDate() + 3);
        result.setHours(day_begin, 0, 0, 0);
      }
      else if (result.getDay() == 5 && result.getHours() < day_begin) {
        // friday before day_begin -> friday day_begin
        result.setHours(day_begin, 0, 0, 0);
      }
      else if (result.getHours() > day_end) {
        // next work_day at day_begin
        result.setDate(result.getDate() + 1);
        result.setHours(day_begin, 0, 0, 0);
      }
      else if (result.getHours() < day_begin) {
        // set hours day_begin
        result.setHours(day_begin, 0, 0, 0);
      }
    }
    

    // round to 00, 10, 20, 30, 40, 50 minutes
    let minutes: number = result.getMinutes();
    if (minutes > 0) {
      if (minutes < 10) {
        result.setMinutes(10);
      }
      else if (minutes > 10 && minutes < 20) {
        result.setMinutes(20);
      }
      else if (minutes > 20 && minutes < 30) {
        result.setMinutes(30);
      }
      else if (minutes > 30 && minutes < 40){
        result.setMinutes(40);
      }
      else {
        result.setMinutes(50);
      }
    }

    result.setSeconds(0);
    result.setMilliseconds(0);

    return result.getTime();
  }
}
