import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  transform(value: any): string {
    if (!value) return '0';

    // 1 000.000
    let formatted: string = value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ');
    return formatted.replace(".", ",");
    // return Numbers.toOurPriceFormat(value);
  }

}
