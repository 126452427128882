import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  /* alerts solved as observables array */
  private _alerts: Array<any> = [];

  private _alertsSubject: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  get getAlerts(): Observable<Array<any>> {
    return this._alertsSubject.asObservable();
  }
  
  constructor(
  ) {
  }


  alert(message: string, type: string, duration?: number) {
    this._alerts.push({
      message: message,
      type: type,
    });
    this._alerts = this._alerts.slice();
    this._alertsSubject.next(this._alerts);

    window.setTimeout(
      () => {
        this._alerts.splice(0, 1);
        this._alerts = this._alerts.slice(0, this._alerts.length);
        this._alertsSubject.next(this._alerts);
      },
      duration || 1000
    );
  }
}
