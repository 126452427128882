import { BookItem } from '../model/book-item.object';
import { Itinerary } from '../model/itinerary.object';
import { Obligation } from '../model/obligation.object';
import { InvoiceTools } from './invoice-tools';
import { OrderTools } from './order-tools';

export class ObligationTools {

  // method for creating obligation objects from api objects
  static buildObligationsFromData(data: Array<any>): any {
    let obligations: Array<Obligation> = [];
    data.forEach(
      o => {
        let obligation = this.buildObligation(o);
        obligations.push(obligation);
      }
    );
    return obligations;
  }

  // method for creating a single obligation object
  static buildObligation(o: any): Obligation {
    let obligation: Obligation = new Obligation();

    if (!o.attachments) {
      o.attachments = [];
    }
    if (!o.files) {
      o.files = [];
    }
    if (o.order_comp_book) {
      let order_comp_book = new BookItem();
      for (let key in o.order_comp_book) {
        // @ts-ignore
        order_comp_book[key] = o.order_comp_book[key];
      }
      o.order_comp_book = order_comp_book;
    }
    if (o.itinerary && o.itinerary.length) {
      let itineraryArr = new Array<Itinerary>();
      o.itinerary.forEach(
        // @ts-ignore
        it => {
          let itinerary = ObligationTools.buildItinerary(it);
          itineraryArr.push(itinerary);
        }
      );
      // @ts-ignore
      itineraryArr = itineraryArr.sort((a, b) => (a.position > b.position) ? 1 : -1);
      o.itinerary = itineraryArr;
    }

    if (o.orders && o.orders.length) {
      o.orders = OrderTools.buildOrdersFromData(o.orders);
    }
    if (o.invoices && o.invoices.length) {
      o.invoices = InvoiceTools.buildInvoicesFromData(o.invoices);
    }

    for (let key in o) {
      // @ts-ignore
      obligation[key] = o[key];
    }

    return obligation;
  }

    
  // method for creating a single obligation object
  static buildItinerary(o: any): Itinerary {
    let itinerary: Itinerary = new Itinerary();
    for (let key in o) {
      // @ts-ignore
      itinerary[key] = o[key];
    }

    return itinerary;
  }
}
