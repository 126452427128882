import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
  name: 'safeResource'
})
export class SafeResourcePipe implements PipeTransform {
  
  constructor(
    private _sanitizer: DomSanitizer
  ) {
  }

  transform(url: string, ...args: unknown[]): unknown {
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
