import { Order } from "./order.object";
import { Obligation } from "./obligation.object";

export class Invoice {
  private _invoice_key: number | null = null;
  get invoice_key(): number | null {
    return this._invoice_key;
  }
  set invoice_key(value: number | null) {
    this._invoice_key = value;
  }

  private _account: string | null = null;
  public get account(): string | null {
    return this._account;
  }
  public set account(value: string | null) {
    this._account = value;
  }

  private _attachment: any;
  public get attachment(): any {
    return this._attachment;
  }
  public set attachment(value: any) {
    this._attachment = value;
  }

  private _attachment_link: string | null = null;
  public get attachment_link(): string | null {
    return this._attachment_link;
  }
  public set attachment_link(value: string | null) {
    this._attachment_link = value;
  }

  private _bank: string | null = null;
  public get bank(): string | null {
    return this._bank;
  }
  public set bank(value: string | null) {
    this._bank = value;
  }

  private _company_key: number | null = null;
  public get company_key(): number | null {
    return this._company_key;
  }
  public set company_key(value: number | null) {
    this._company_key = value;
  }

  private _comp_book: any;
  public get comp_book(): any {
    return this._comp_book;
  }
  public set comp_book(value: any) {
    this._comp_book = value;
  }

  private _comp_book_key: number | null = null;
  public get comp_book_key(): number | null {
    return this._comp_book_key;
  }
  public set comp_book_key(value: number | null) {
    this._comp_book_key = value;
  }
  
  private _comp_name: string | null = null;
  public get comp_name(): string | null {
    return this._comp_name;
  }
  public set comp_name(value: string | null) {
    this._comp_name = value;
  }
  
  private _comp_city: string | null = null;
  public get comp_city(): string | null {
    return this._comp_city;
  }
  public set comp_city(value: string | null) {
    this._comp_city = value;
  }

  private _comp_country: string | null = null;
  public get comp_country(): string | null {
    return this._comp_country;
  }
  public set comp_country(value: string | null) {
    this._comp_country = value;
  }
  
  private _comp_street: string | null = null;
  public get comp_street(): string | null {
    return this._comp_street;
  }
  public set comp_street(value: string | null) {
    this._comp_street = value;
  }

  private _comp_zip: string | null = null;
  public get comp_zip(): string | null {
    return this._comp_zip;
  }
  public set comp_zip(value: string | null) {
    this._comp_zip = value;
  }
  
  private _constant_symbol: string | null = null;
  public get constant_symbol(): string | null {
    return this._constant_symbol;
  }
  public set constant_symbol(value: string | null) {
    this._constant_symbol = value;
  }

  private _contact_email: string | null = null;
  public get contact_email(): string | null {
    return this._contact_email;
  }
  public set contact_email(value: string | null) {
    this._contact_email = value;
  }

  private _contact_name: string | null = null;
  public get contact_name(): string | null {
    return this._contact_name;
  }
  public set contact_name(value: string | null) {
    this._contact_name = value;
  }

  private _contact_phone: string | null = null;
  public get contact_phone(): string | null {
    return this._contact_phone;
  }
  public set contact_phone(value: string | null) {
    this._contact_phone = value;
  }

  private _currency: string | null = null;
  get currency(): string | null {
    return this._currency;
  }
  set currency(value: string | null) {
    this._currency = value;
  }

  private _description: string | null = null;
  public get description(): string | null {
    return this._description;
  }
  public set description(value: string | null) {
    this._description = value;
  }

  private _emailed: string | null = null;
  public get emailed(): string | null {
    return this._emailed;
  }
  public set emailed(value: string | null) {
    this._emailed = value;
  }

  private _email_msg_ids: Array<string> = [];
  public get email_msg_ids(): Array<string> {
    return this._email_msg_ids;
  }
  public set email_msg_ids(value: Array<string>) {
    this._email_msg_ids = value;
  }

  private _email_headers: Array<any> = [];
  public get email_headers(): Array<any> {
    return this._email_headers;
  }
  public set email_headers(value: Array<any>) {
    this._email_headers = value;
  }

  private _exchange_rate: number | null = null;
  public get exchange_rate(): number | null {
    return this._exchange_rate;
  }
  public set exchange_rate(value: number | null) {
    this._exchange_rate = value;
  }

  private _factoring: boolean | null = null;
  public get factoring(): boolean | null {
    return this._factoring;
  }
  public set factoring(value: boolean | null) {
    this._factoring = value;
  }

  private _fullfilment: string | null = null;
  public get fullfilment(): string | null {
    return this._fullfilment;
  }
  public set fullfilment(value: string | null) {
    this._fullfilment = value;
    if (this._fullfilment) {
      this._fullfilmentDate = new Date(this._fullfilment);
    }
  }

  private _fullfilmentDate: Date | null = null;
  public get fullfilmentDate(): Date | null {
    return this._fullfilmentDate;
  }

  private _iban: string | null = null;
  public get iban(): string | null {
    return this._iban;
  }
  public set iban(value: string | null) {
    this._iban = value;
  }
  
  private _issued: string | null = null;
  public get issued(): string | null {
    return this._issued;
  }
  public set issued(value: string | null) {
    this._issued = value;
    if (this._issued) {
      this._issuedDate = new Date(this._issued);
    }
  }

  private _issuedDate: Date | null = null;
  public get issuedDate(): Date | null {
    return this._issuedDate;
  }

  private _items: Array<any> = [];
  public get items(): Array<any> {
    return this._items;
  }
  public set items(value: Array<any>) {
    this._items = value;
  }

  private _mail_city: string | null = null;
  public get mail_city(): string | null {
    return this._mail_city;
  }
  public set mail_city(value: string | null) {
    this._mail_city = value;
  }

  private _mail_country: string | null = null;
  public get mail_country(): string | null {
    return this._mail_country;
  }
  public set mail_country(value: string | null) {
    this._mail_country = value;
  }

  private _mail_name: string | null = null;
  public get mail_name(): string | null {
    return this._mail_name;
  }
  public set mail_name(value: string | null) {
    this._mail_name = value;
  }
  
  private _mail_street: string | null = null;
  public get mail_street(): string | null {
    return this._mail_street;
  }
  public set mail_street(value: string | null) {
    this._mail_street = value;
  }
  
  private _mail_zip: string | null = null;
  public get mail_zip(): string | null {
    return this._mail_zip;
  }
  public set mail_zip(value: string | null) {
    this._mail_zip = value;
  }

  private _maturity: string | null = null;
  get maturity(): string | null {
    return this._maturity;
  }
  set maturity(value: string | null) {
    this._maturity = value;
    if (this._maturity) {
      this._maturityDate = new Date(this._maturity);
      // this._maturityDate10 = new Date(this._maturity);
      // this._maturityDate10.setDate(this._maturityDate.getDate() + 10);

      // compute remaining days
      let currentDate = new Date().getTime();
      let maturity = this._maturityDate.getTime();
      const diffTime = maturity - currentDate;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      this._maturityDaysRemaining = diffDays;
    }
  }
  
  private _maturityDate: Date | null = null;
  public get maturityDate(): Date | null {
    return this._maturityDate;
  }

  private _maturityDaysRemaining: number | null = null;
  public get maturityDaysRemaining(): number | null {
    return this._maturityDaysRemaining;
  }
  
  private _number: number | null = null;
  public get number(): number | null {
    return this._number;
  }
  public set number(value: number | null) {
    this._number = value;
  }

  private _obligations: Array<Obligation> = [];
  public get obligations(): Array<Obligation> {
    return this._obligations;
  }
  public set obligations(value: Array<Obligation>) {
    this._obligations = value;
  }

  private _orders: Array<Order> = [];
  get orders(): Array<Order> {
    return this._orders;
  }
  set orders(value: Array<Order>) {
    this._orders = value;
  }

  private _payment: string | null = null;
  public get payment(): string | null {
    return this._payment;
  }
  public set payment(value: string | null) {
    this._payment = value;
    if (this._payment) {
      this._paymentDate = new Date(this._payment);
      
      if (this.maturityDate) {
        let payment = this._paymentDate.getTime();
        let maturity = this.maturityDate.getTime();
        const diffTime = payment - maturity;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        this._paymentAfterMaturity = diffDays;
      }
    }
  }

  private _paymentDate: Date | null = null;
  public get paymentDate(): Date | null {
    return this._paymentDate;
  }
  
  private _paymentAfterMaturity: number | null = null;
  public get paymentAfterMaturity(): number | null {
    return this._paymentAfterMaturity;
  }

  private _payment_method: string | null = null;
  public get payment_method(): string | null {
    return this._payment_method;
  }
  public set payment_method(value: string | null) {
    this._payment_method = value;
  }

  private _price: number | null = 0;
  get price(): number | null {
    return this._price;
  }
  set price(value: number | null) {
    this._price = value;
  }

  private _price_total: number | null = 0;
  public get price_total(): number | null {
    return this._price_total;
  }
  public set price_total(value: number | null) {
    this._price_total = value;
  }
  
  private _printed: boolean | null = null;
  public get printed(): boolean | null {
    return this._printed;
  }
  public set printed(value: boolean | null) {
    this._printed = value;
  }

  private _received_invoice_no: string | null = null;
  public get received_invoice_no(): string | null {
    return this._received_invoice_no;
  }
  public set received_invoice_no(value: string | null) {
    this._received_invoice_no = value;
  }

  private _series: number | null = null;
  public get series(): number | null {
    return this._series;
  }
  public set series(value: number | null) {
    this._series = value;
  }

  private _specific_symbol: string | null = null;
  public get specific_symbol(): string | null {
    return this._specific_symbol;
  }
  public set specific_symbol(value: string | null) {
    this._specific_symbol = value;
  }
  
  private _swift: string | null = null;
  public get swift(): string | null {
    return this._swift;
  }
  public set swift(value: string | null) {
    this._swift = value;
  }

  private _type: string | null = null;
  public get type(): string | null {
    return this._type;
  }
  public set type(value: string | null) {
    this._type = value;
  }
  
  private _updated_by: string | null = null;
  public get updated_by(): string | null {
    return this._updated_by;
  }
  public set updated_by(value: string | null) {
    this._updated_by = value;
  }
  
  private _updated_time: string | null = null;
  public get updated_time(): string | null {
    return this._updated_time;
  }
  public set updated_time(value: string | null) {
    this._updated_time = value;
  }

  private _variable_symbol: string | null = null;
  get variable_symbol(): string | null {
    return this._variable_symbol;
  }
  set variable_symbol(value: string | null) {
    this._variable_symbol = value;
  }
  
  private _vat1: number | null = 0;
  public get vat1(): number | null {
    return this._vat1;
  }
  public set vat1(value: number | null) {
    this._vat1 = value;
  }
  
  private _vat1_rate: number | null = 0;
  public get vat1_rate(): number | null {
    return this._vat1_rate;
  }
  public set vat1_rate(value: number | null) {
    this._vat1_rate = value;
  }
  
  private _vat2: number | null = 0;
  public get vat2(): number | null {
    return this._vat2;
  }
  public set vat2(value: number | null) {
    this._vat2 = value;
  }

  private _vat2_rate: number | null = 0;
  public get vat2_rate(): number | null {
    return this._vat2_rate;
  }
  public set vat2_rate(value: number | null) {
    this._vat2_rate = value;
  }

  private _vat_home: number | null = 0;
  public get vat_home(): number | null {
    return this._vat_home;
  }
  public set vat_home(value: number | null) {
    this._vat_home = value;
  }

  private _year: number | null = null;
  public get year(): number | null {
    return this._year;
  }
  public set year(value: number | null) {
    this._year = value;
  }

  public get invoiceNumberFormatted(): string | null {
    if (!this._variable_symbol) {
      if (this._series && this._year && this._number) {
        return (this._type == 'R' ? 'PF' : 'FA') + this._series.toString() + this._year.toString() + this._number.toString();
      }
      return '';
    }
    // I = issued -> FA, R = received -> PF
    return (this._type == 'R' ? 'PF' : 'FA') + this._variable_symbol;
  }
}
