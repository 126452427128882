import { DateTools } from "../tools/date-tools";
import { Itinerary } from "./itinerary.object";
import { Order } from "./order.object";

export class Obligation {
  private _obligation_key: number | null = null;
  get obligation_key(): number | null {
    return this._obligation_key;
  }
  set obligation_key(value: number | null) {
    this._obligation_key = value;
  }

  private _express_route_key: number | null = null
  public get express_route_key(): number | null {
    return this._express_route_key;
  }
  public set express_route_key(value: number | null) {
    this._express_route_key = value;
  }

  private _attachment: string | null = null; 
  get attachment(): string | null {
    return this._attachment;
  }
  set attachment(value: string | null) {
    this._attachment = value;
  }

  private _attachments: Array<any> = [];
  public get attachments(): Array<any> {
    return this._attachments;
  }
  public set attachments(value: Array<any>) {
    this._attachments = value;
  }

  private _attachment_link: string | null = null; 
  get attachment_link(): string | null {
    return this._attachment_link;
  }
  set attachment_link(value: string | null) {
    this._attachment_link = value;
  }

  private _calculated_price: number | null = null
  get calculated_price(): number | null {
    return this._calculated_price;
  }
  set calculated_price(value: number | null) {
    this._calculated_price = value;
  }

  private _car: any;
  get car(): any {
    return this._car;
  }
  set car(value: any) {
    this._car = value;
  }
  
  private _car_adr: boolean | null = null;
  get car_adr(): boolean | null {
    return this._car_adr;
  }
  set car_adr(value: boolean | null) {
    this._car_adr = value;
  }

  private _car_height: number | null = null;
  get car_height(): number | null {
    return this._car_height;
  }
  set car_height(value: number | null) {
    this._car_height = value;
  }

  private _car_key: number | null = null;
  get car_key(): number | null {
    return this._car_key;
  }
  set car_key(value: number | null) {
    this._car_key = value;
  }

  private _car_key_temp: number | null = null
  public get car_key_temp(): number | null {
    return this._car_key_temp;
  }
  public set car_key_temp(value: number | null) {
    this._car_key_temp = value;
  }

  private _car_length: number | null = null;
  get car_length(): number | null {
    return this._car_length;
  }
  set car_length(value: number | null) {
    this._car_length = value;
  }

  private _car_lifting_arm: boolean | null = null;
  get car_lifting_arm(): boolean | null {
    return this._car_lifting_arm;
  }
  set car_lifting_arm(value: boolean | null) {
    this._car_lifting_arm = value;
  }

  private _car_lifting_tailboard: boolean | null = null;
  get car_lifting_tailboard(): boolean | null {
    return this._car_lifting_tailboard;
  }
  set car_lifting_tailboard(value: boolean | null) {
    this._car_lifting_tailboard = value;
  }

  private _car_loading_type: string | null = null; 
  public get car_loading_type(): string | null {
    return this._car_loading_type;
  }
  public set car_loading_type(value: string | null) {
    this._car_loading_type = value;
  }

  private _car_more_drivers: boolean | null = null;
  get car_more_drivers(): boolean | null {
    return this._car_more_drivers;
  }
  set car_more_drivers(value: boolean | null) {
    this._car_more_drivers = value;
  }

  private _car_oversize: boolean | null = null;
  get car_oversize(): boolean | null {
    return this._car_oversize;
  }
  set car_oversize(value: boolean | null) {
    this._car_oversize = value;
  }

  private _car_tonnage: number | null = null;
  get car_tonnage(): number | null {
    return this._car_tonnage;
  }
  set car_tonnage(value: number | null) {
    this._car_tonnage = value;
  }

  private _car_trailer: string | null = null; 
  public get car_trailer(): string | null {
    return this._car_trailer;
  }
  public set car_trailer(value: string | null) {
    this._car_trailer = value;
  }
  
  private _car_type: string | null = null; 
  public get car_type(): string | null {
    return this._car_type;
  }
  public set car_type(value: string | null) {
    this._car_type = value;
  }

  private _car_width: number | null = null;
  get car_width(): number | null {
    return this._car_width;
  }
  set car_width(value: number | null) {
    this._car_width = value;
  }

  private _cargo_gather: boolean | null = null;
  get cargo_gather(): boolean | null {
    return this._cargo_gather;
  }
  set cargo_gather(value: boolean | null) {
    this._cargo_gather = value;
  }

  private _code: string | null = null; 
  get code(): string | null {
    return this._code;
  }
  set code(value: string | null) {
    this._code = value;
  }

  private _company_key: number | null = null;
  get company_key(): number | null {
    return this._company_key;
  }
  set company_key(value: number | null) {
    this._company_key = value;
  }

  private _contracted_price: number | null = null
  get contracted_price(): number | null {
    return this._contracted_price;
  }
  set contracted_price(value: number | null) {
    this._contracted_price = value;
  }

  private _created_time: string | null = null; 
  get created_time(): string | null {
    return this._created_time;
  }
  set created_time(value: string | null) {
    this._created_time = value;
    if (this._created_time) {
      this.created_time_date = DateTools.apiIsoToDate(this._created_time);
    }
  }
  
  private _created_time_date: Date | null = null;
  get created_time_date(): Date | null {
    return this._created_time_date;
  }
  set created_time_date(value: Date | null) {
    this._created_time_date = value;
  }

  private _currency: string | null = null; 
  get currency(): string | null {
    return this._currency;
  }
  set currency(value: string | null) {
    this._currency = value;
  }
  
  private _custom_order_number: string | null = null; 
  public get custom_order_number(): string | null {
    return this._custom_order_number;
  }
  public set custom_order_number(value: string | null) {
    this._custom_order_number = value;
  }

  private _express_delivery: boolean | null = null;
  get express_delivery(): boolean | null {
    return this._express_delivery;
  }
  set express_delivery(value: boolean | null) {
    this._express_delivery = value;
  }
  
  private _exported_to: Array<any> = [];
  public get exported_to(): Array<any> {
    return this._exported_to;
  }
  public set exported_to(value: Array<any>) {
    this._exported_to = value;
  }

  private _itinerary: Array<Itinerary> = [];
  get itinerary(): Array<Itinerary> {
    return this._itinerary;
  }
  set itinerary(value: Array<Itinerary>) {
    this._itinerary = value;
  }
  
  get first_itinerary_time(): Date | null {
    if (this._itinerary && this._itinerary.length) {
      let first = this._itinerary.find(i => i.type == 'L' || i.type == 'U');
      if (first) {
        return first.arrival_time_date;
      }
    }
    return null;
  }

  get last_itinerary_time(): Date | null {
    if (this._itinerary && this._itinerary.length) {
      let last = this._itinerary[this._itinerary.length - 1];
      if (last) {
        return last.arrival_time_date;
      }
    }
    return null;
  }

  private _favourite: boolean | null = null;
  get favourite(): boolean | null {
    return this._favourite;
  }
  set favourite(value: boolean | null) {
    this._favourite = value;
  }

  private _files: Array<any> = [];
  public get files(): Array<any> {
    return this._files;
  }
  public set files(value: Array<any>) {
    this._files = value;
  }

  private _length: number | null = null
  get length(): number | null {
    return this._length;
  }
  set length(value: number | null) {
    this._length = value;
  }

  private _note: string | null = null; 
  get note(): string | null {
    return this._note;
  }
  set note(value: string | null) {
    this._note = value;
  }

  private _note_price: string | null = null; 
  get note_price(): string | null {
    return this._note_price;
  }
  set note_price(value: string | null) {
    this._note_price = value;
  }

  private _number: number | null = null;
  public get number(): number | null {
    return this._number;
  }
  public set number(value: number | null) {
    this._number = value;
  }

  private _orders: Array<Order> = [];
  get orders(): Array<Order> {
    return this._orders;
  }
  set orders(value: Array<Order>) {
    this._orders = value;
  }

  private _order_comp_book: any;
  get order_comp_book(): any {
    return this._order_comp_book;
  }
  set order_comp_book(value: any) {
    this._order_comp_book = value;
  }

  get order_comp_name(): string | null {
    return this._order_comp_book ? this._order_comp_book.company : '';
  }

  private _order_comp_book_key: number | null = null;
  get order_comp_book_key(): number | null {
    return this._order_comp_book_key;
  }
  set order_comp_book_key(value: number | null) {
    this._order_comp_book_key = value;
  }

  private _order_number: string | null = null; 
  get order_number(): string | null {
    return this._order_number;
  }
  set order_number(value: string | null) {
    this._order_number = value;
  }

  // type "any" because of autocompleters ngbtypeahead
  private _payer_contact: any;
  get payer_contact(): any {
    return this._payer_contact;
  }
  set payer_contact(value: any) {
    this._payer_contact = value;
  }

  private _payer_email: any;
  get payer_email(): any {
    return this._payer_email;
  }
  set payer_email(value: any) {
    this._payer_email = value;
  }

  private _payer_phone: any;
  get payer_phone(): any {
    return this._payer_phone;
  }
  set payer_phone(value: any) {
    this._payer_phone = value;
  }

  private _piece_shipment: boolean | null = null;
  get piece_shipment(): boolean | null {
    return this._piece_shipment;
  }
  set piece_shipment(value: boolean | null) {
    this._piece_shipment = value;
  }
  
  get warehouse_itinerary(): Itinerary | null {
    if (this._itinerary && this._itinerary.length) {
      let w = this._itinerary.find(i => i.type == "W" || i.type == "V");
      if (w) {
        return w;
      }
    }
    return null;
  }

  // 1st itinerary to warehouse itinerary
  get itinerary_warehouse_collection(): Array<Itinerary> {
    // @ts-ignore
    let idx: number = this.itinerary.indexOf(this.warehouse_itinerary);
    if (idx > -1) {
      return this._itinerary.slice(0, idx + 1);
    }
    return this._itinerary;
  }
  
  // warehouse itinerary to delivery itinerary
  get itinerary_warehouse_delivery(): Array<Itinerary> {
    // @ts-ignore
    let idx: number = this.itinerary.indexOf(this.warehouse_itinerary);
    if (idx > -1) {
      return this._itinerary.slice(idx);
    }
    return this._itinerary;
  }

  private _order_number_standard: string | null = null; 
  get order_number_standard(): string | null {
    return this._order_number_standard;
  }
  set order_number_standard(value: string | null) {
    this._order_number_standard = value;
  }

  private _route_length: number | null = null
  get route_length(): number | null {
    return this._route_length;
  }
  set route_length(value: number | null) {
    this._route_length = value;
    if (value) {
      this._route_length_rounded = Math.round(value);
    }
  }

  private _route_length_rounded: number = 0;
  public get route_length_rounded(): number | null {
    return this._route_length_rounded;
  }

  private _route_length_real: number | null = null
  public get route_length_real(): number | null {
    return this._route_length_real;
  }
  public set route_length_real(value: number | null) {
    this._route_length_real = value;
    if (value) {
      this._route_length_real_rounded = Math.round(value);
    }
  }

  private _route_length_real_rounded: number = 0;
  public get route_length_real_rounded(): number | null {
    return this._route_length_real_rounded;
  }

  private _route_length_estimated: number | null = 0;
  public get route_length_estimated(): number | null {
    return this._route_length_estimated;
  }
  public set route_length_estimated(value: number | null) {
    this._route_length_estimated = value;
  }

  private _route_map_url: string | null = null; 
  get route_map_url(): string | null {
    return this._route_map_url;
  }
  set route_map_url(value: string | null) {
    this._route_map_url = value;
  }

  private _series: number | null = null
  get series(): number | null {
    return this._series;
  }
  set series(value: number | null) {
    this._series = value;
  }

  private _status: string | null = "E";
  get status(): string | null {
    return this._status;
  }
  set status(value: string | null) {
    this._status = value;
  }
  

  private _allow_subnumber: boolean | null = false;
  public get allow_subnumber(): boolean | null {
    return this._allow_subnumber;
  }
  public set allow_subnumber(value: boolean | null) {
    this._allow_subnumber = value;
  }

  private _subnumber: number | null = 1;
  public get subnumber(): number | null {
    return this._subnumber;
  }
  public set subnumber(value: number | null) {
    this._subnumber = value;
  }

  private _updated_by: string | null = null; 
  get updated_by(): string | null {
    return this._updated_by;
  }
  set updated_by(value: string | null) {
    this._updated_by = value;
  }

  private _updated_time: string | null = null; 
  get updated_time(): string | null {
    return this._updated_time;
  }
  set updated_time(value: string | null) {
    this._updated_time = value;
  }
  
  private _transshipment: boolean = false;
  public get transshipment(): boolean {
    return this._transshipment;
  }
  public set transshipment(value: boolean) {
    this._transshipment = value;
  }
  
  get transshipment_itinerary(): Itinerary | null {
    if (this._itinerary && this._itinerary.length) {
      let it = this._itinerary.find(i => i.type == "T");
      if (it) {
        return it;
      }
    }
    return null;
  }

  // 1st itinerary to transshipment itinerary
  get itinerary_transshipment_collection(): Array<Itinerary> {
    // @ts-ignore
    let idx: number = this.itinerary.indexOf(this.transshipment_itinerary);
    if (idx > -1) {
      return this._itinerary.slice(0, idx + 1);
    }
    return this._itinerary;
  }
  
  // transshipment itinerary to delivery itinerary
  get itinerary_transshipment_delivery(): Array<Itinerary> {
    // @ts-ignore
    let idx: number = this.itinerary.indexOf(this.transshipment_itinerary);
    if (idx > -1) {
      return this._itinerary.slice(idx);
    }
    return this._itinerary;
  }

  private _transit_length_real: number | null = null
  public get transit_length_real(): number | null {
    return this._transit_length_real;
  }
  public set transit_length_real(value: number | null) {
    this._transit_length_real = value;
  }

  private _volume: number | null = null
  get volume(): number | null {
    return this._volume;
  }
  set volume(value: number | null) {
    this._volume = value;
  }

  private _ware_type: string | null = null; 
  get ware_type(): string | null {
    return this._ware_type;
  }
  set ware_type(value: string | null) {
    this._ware_type = value;
  }

  private _weight: number | null = null
  get weight(): number | null {
    return this._weight;
  }
  set weight(value: number | null) {
    this._weight = value;
  }

  private _year: number | null = new Date().getFullYear();
  get year(): number | null {
    return this._year;
  }
  set year(value: number | null) {
    this._year = value;
  }

  public get obligationNumberFormatted(): string | null {
    if (!this._order_number_standard) {
      return '';
    }
    return 'ZA' + this._order_number_standard;
  }

}
