import { DatePipe } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { serviceConfiguration } from "../config";


@Injectable({
  providedIn: 'root',
})
export class TruckAgendaErrorHandler implements ErrorHandler {
  
  private _subscribed: Array<Subscription> = [];

  constructor(
    private _http: HttpClient,
    private _datePipe: DatePipe
  ) {
  }

  // max errors logs count (no spams for the same error produced many times at the same time)
  private _MAX_ERRORS_LOG: number = 5;
  private _errorCounter: number = 0;

  // POST /internal/bug-report
  createBugReport(error: string): Observable<any> {
    let url: string = serviceConfiguration.internal.bug_report;
    // let headers = new HttpHeaders({ 'Content-Type': 'text/plain' });
    
    var file = new Blob([error], {type: 'text/plain'});
    let formData = new FormData();
    formData.append('file', file);

    return this._http.post(url, formData);
    // return this._http.post(url, error);
  }

  // error handler
  handleError(error: any): void {
    console.error('Nastala neočekávaná událost - kontaktuje podporu.');
    console.log(error);

    // restrict email logging 
    // for total error cases would be hundrends of unnecessary emails
    this._errorCounter += 1;
    if (this._errorCounter < this._MAX_ERRORS_LOG) {
      let error_report: string = '';
      error_report += this._datePipe.transform(new Date(), 'dd.MM.yyyy HH:mm:ss') + '\n';
      error_report += window.location.href + '\n\n';
      // error.stack includes the full text/message of error
      error_report += 'Error.stack:\n' + (error.stack ? error.stack : error) + '\n\n\n';
      
      // error.message
      error_report += 'Error.message:\n' + (error.message ? error.message : error) + '\n\n\n';

      // error.stringify
      error_report += 'Error.stringify:\n' + (error ? JSON.stringify(error) : error);

      this._subscribed.push(
        this.createBugReport(error_report).subscribe(
          response => {
            if (response) {
              console.log(response);
            }
          }
        )
      );
    }
  }
}