import { GoogleLatLng } from "./google-lat-lng.object";

declare var google: any;

export class GoogleMapMarker {

  private _googleMarker: any;
  private events: Array<any> = [];
  
  private _data: any = {};
  get data(): any {
    return this._data;
  }
  setData(key: string, value: any) {
    this._data[key] = value;
  }
  getData(key: string): any {
    return this._data[key];
  }

  get anchorPoint(): any {
    return this.getGoogleMarker().getAnchorPoint();
  }
  set anchorPoint(value: any) {
    this.getGoogleMarker().setAnchorPoint(value)
  }

  // The offset from the marker's position to the tip of an InfoWindow that has been opened with the marker as anchor.
  get animation(): any {
    return this.getGoogleMarker().getAnimation();
  }
  set animation(value: any) {
    this.getGoogleMarker().setAnimation(value)
  }

  //Which animation to play when marker is added to a map.
  get attribution(): any {
    return this.getGoogleMarker().getAttribution();
  }
  set attribution(value: any) {
    this.getGoogleMarker().setAttribution(value)
  }

  // Contains all the information needed to identify your application as the source of a save. In this context, 'place' means a business, point of interest or geographic location. attribution must be specified with a place in order to enable a save.
  private _clickable: boolean = false;
  get clickable(): boolean {
    return this.getGoogleMarker().getClickable();
  }
  set clickable(value: boolean) {
    this.getGoogleMarker().setClickable(value)
  }

  // If true, the marker receives mouse and touch events. Default value is true.
  get crossOnDrag(): boolean {
    return this.getGoogleMarker().getCrossOnDrag();
  }
  set crossOnDrag(value: boolean) {
    this.getGoogleMarker().setCrossOnDrag(value)
  }

  // If false, disables cross that appears beneath the marker when dragging. This option is true by default.
  get cursor(): boolean {
    return this.getGoogleMarker().getCursor();
  }
  set cursor(value: boolean) {
    this.getGoogleMarker().setCursor(value)
  }

  // Mouse cursor to show on hover
  get draggable(): boolean {
    return this.getGoogleMarker().getDraggable();
  }
  set draggable(value: boolean) {
    this.getGoogleMarker().setDraggable(value);
  }

  // If true, the marker can be dragged. Default value is false.
  get icon(): any {
    return this.getGoogleMarker().getIcon();
  }
  set icon(value: any) {
    this.getGoogleMarker().setIcon(value);
  }

  // Icon for the foreground. If a string is provided, it is treated as though it were an Icon with the string as url.
  get label(): string {
    return this.getGoogleMarker().getLabel();
  }
  set label(value: string) {
    this.getGoogleMarker().setLabel(value)
  }

  // Adds a label to the marker. The label can either be a string, or a MarkerLabel object. Only the first character of the string will be displayed.
  get map(): any {
    return this.getGoogleMarker().getMap();
  }
  set map(value: any) {
    this.getGoogleMarker().setMap(value);
  }

  // Map on which to display Marker.
  get opacity(): number {
    return this.getGoogleMarker().getOpacity();
  }
  set opacity(value: number) {
    this.getGoogleMarker().setOpacity(value)
  }

  // The marker's opacity between 0.0 and 1.0.
  get optimized(): boolean {
    return this.getGoogleMarker().getOptimized();
  }
  set optimized(value: boolean) {
    this.getGoogleMarker().setOptimized(value)
  }

  // Optimization renders many markers as a single static element. Optimized rendering is enabled by default. Disable optimized rendering for animated GIFs or PNGs, or when each marker must be rendered as a separate DOM element (advanced usage only).
  private _place: any;//:MarkerPlace;
  get place(): any {
    return this.getGoogleMarker().getPlace();
  }
  set place(value: any) {
    this.getGoogleMarker().setPlace(value)
  }

  // Place information, used to identify and describe the place associated with this Marker. In this context, 'place' means a business, point of interest or geographic location. To allow a user to save this place, open an info window anchored on this marker. The info window will contain information about the place and an option for the user to save it. Only one of position or place can be specified.
  get position(): any {
    return this.getGoogleMarker().getPosition();
  }
  set position(value: any) {
    if (!value) return;
    if (value instanceof GoogleLatLng) {
      let lat: number = value.lat;
      let lng: number = value.lng;
      this.getGoogleMarker().setPosition(new google.maps.LatLng(lat, lng));
    }
    else if (value.lat() && value.lng()) {
      let lat: number = value.lat();
      let lng: number = value.lng();
      this.getGoogleMarker().setPosition(new google.maps.LatLng(lat, lng));
    } 
  }

  // Marker position. Required.
  private _shape: any//:MarkerShape;
  get shape(): any {
    return this.getGoogleMarker().getShape();
  }
  set shape(value: any) {
    this.getGoogleMarker().setShape(value)
  }

  // Image map region definition used for drag/click.
  private _title: any;
  get title(): any {
    return this.getGoogleMarker().getTitle();
  }
  set title(value: any) {
    this.getGoogleMarker().setTitle(value)
  }

  // Rollover text
  get visible(): boolean {
    return this.getGoogleMarker().getVisible();
  }
  set visible(value: boolean) {
    this.getGoogleMarker().setVisible(value)
  }

  // If true, the marker is visible
  get zIndex(): number {
    return this.getGoogleMarker().getZIndex();
  }
  set zIndex(value: number) {
    this.getGoogleMarker().setZIndex(value)
  }

  // All markers are displayed on the map in order of their zIndex, with higher values displaying in front of markers with lower values. By default, markers are displayed according to their vertical position on screen, with lower markers appearing in front of markers further up the screen.
  // get iwText() {
  //   return this._map.getIwText();
  // }
  // set iwText(value: string) {
  //   this._iwText = value;
  // }

  public getGoogleMarker(): any {
    if (!this._googleMarker) {
      this._googleMarker = new google.maps.Marker();
    }
    return this._googleMarker;
  }

  addListener(any: any, any2: any) {
    this.events.push(this.getGoogleMarker().addListener(any, any2));
  }

  clearEvents(): void {
    this.events.forEach(
      event => {
        if (event) event.remove()
      }
    );
  }
}

