import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { serviceConfiguration } from '../config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExchangeRatesService {

  private _loadingExchangeRates: boolean = false;
  get loadingExchangeRates(): boolean {
    return this._loadingExchangeRates;
  }

  constructor(
    private _http: HttpClient,
    private _datePipe: DatePipe
  ) {
  }

  loadExchangeRates(country: string, currency: string, validAt: string): Observable<any> {
    let url: string = serviceConfiguration.exchangeRates.api;
    if (country && currency && validAt) {
      url += '/?country=' + country;
      url += '&currency=' + currency;
      url += '&valid_at=' + validAt;
    }

    return this._http.get(url);
  }
}
