import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertService } from './service/alert.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    AlertService
  ],
})
export class AppComponent implements OnDestroy {
  title = 'TM externí aplikace';

  private _subscribed: Array<Subscription> = [];

  public alerts: Array<any> = [];

  constructor(
    private _alertServ: AlertService,
  ) {
    this._subscribed.push(
      this._alertServ.getAlerts.subscribe(
        alerts => {
          this.alerts = alerts;
        }
      )
    );
  }

  ngOnDestroy(): void {
    this._subscribed.forEach(
      subsriber => {
        subsriber.unsubscribe();
      }
    );
    this._subscribed = [];
  }

}
