import { Invoice } from '../model/invoice.object';
import { Obligation } from '../model/obligation.object';
import { Order } from '../model/order.object';
import { ObligationTools } from './obligation-tools';
import { OrderTools } from './order-tools';

// Tools for building invoice objects (used in more services)
export class InvoiceTools {
    
  // method for creating invoice objects from api objects
  static buildInvoicesFromData(data: Array<any>): any {
    let invoices: Array<Invoice> = [];
    data.forEach(
      i => {
        let invoice = InvoiceTools.buildInvoice(i);
        invoices.push(invoice);
      }
    );
    return invoices;
  }

  // method for creating a single invoice object
  static buildInvoice(i: any): Invoice {
    let invoice: Invoice = new Invoice();

    if (i.obligations && i.obligations.length) {
      i.obligations = ObligationTools.buildObligationsFromData(i.obligations);
    }
    if (i.orders && i.orders.length) {
      i.orders = OrderTools.buildOrdersFromData(i.orders);
    }

    for (let key in i) {
      // @ts-ignore
      invoice[key] = i[key];
    }

    return invoice;
  }

}
