import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { RecaptchaModule } from 'angular-google-recaptcha';

import { AppRoutingModule } from './app-routing.module';
import { TruckAgendaErrorHandler } from './service/truckagenda-error.handler';
import { AppComponent } from './app.component';
import { ExtOrderComponent } from './components/ext-order/ext-order.component';
import { DefaultComponent } from './components/default/default.component';
import { ItineraryConfirmationComponent } from './components/itinerary-confirmation/itinerary-confirmation.component';
import { ItineraryDiaryComponent } from './components/itinerary-diary/itinerary-diary.component';
import { ExtItineraryDiaryComponent } from './components/ext-itinerary-diary/ext-itinerary-diary.component';
import { ExtObligationComponent } from './components/ext-obligation/ext-obligation.component';
import { GoogleMapComponent } from './components/google-map/google-map.component';
import { SafeResourcePipe } from './pipe/safe-resource.pipe';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { PricePipe } from './pipe/price.pipe';

@NgModule({
  declarations: [
    PricePipe,
    SafeResourcePipe,
    SafeHtmlPipe,
    AppComponent,
    ExtOrderComponent,
    DefaultComponent,
    ExtObligationComponent,
    ItineraryConfirmationComponent,
    ItineraryDiaryComponent,
    ExtItineraryDiaryComponent,
    GoogleMapComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule,
    NgxSliderModule,
    RecaptchaModule.forRoot({siteKey: '6Lcvt8UUAAAAAHFAYec37VLmmHeSUi6TWtSBbLC5'})
  ],
  providers: [
    DatePipe,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: ErrorHandler, useClass: TruckAgendaErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
