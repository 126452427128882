<div *ngIf="loading" class="container text-center">
  <div class="spinner-wrapper py-4">
    <div class="spinner-border text-primary" style="width:4rem; height:4rem;" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
</div>

<!-- *ngIf="!loading && dictionary" -->
<div *ngIf="!loading && dictionary" class="container border rounded my-4 px-4 py-2" 
style="border-color: #444444 !important">
  <div class="row border-bottom border-2 py-2" [style]="'border-color:' + borderColor + ' !important'">
    <div class="col-sm-12 d-flex">
      <div *ngIf="logo" class="me-5">
        <img [src]="logo.content | safeResource" height="80px" alt="logo"/>
      </div>
        
      <div>
        <div *ngIf="company">
          <div>{{company.company}}</div>
          <div>{{company.street}}, {{company.zip}} {{company.city}} ({{company.country}})</div>
          <div>{{dictionary['DIČ'][country]}}: {{company.tin}}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="row py-2">
    <div class="col-md-12 mt-3">
      <h5 class="m-0">
        {{dictionary['net-Objednávka'][country]}}
        {{bookItem ? (bookItem.company + ' (' + bookItem.tin + ')') : ''}}
      </h5>
    </div>
  </div>

  <!-- obligation data -->
  <div class="row py-2">
    <div class="col-md-6">
      <div class="form-group mb-1">
        <label class="text-nowrap">{{dictionary['ČísloObjednávky'][country]}}</label>
        <input class="form-control" type="text" [(ngModel)]="obligation.order_number"/>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group mb-1">
        <label class="text-nowrap">{{dictionary['Code'][country]}}</label>
        <input class="form-control" type="text" [(ngModel)]="obligation.code" placeholder="Doplňte"/>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group mb-1">
        <label class="text-nowrap">{{dictionary['Poznámka'][country]}}</label>
        <input class="form-control" type="text" [(ngModel)]="obligation.note" placeholder="Doplňte"/>
      </div>
    </div>
  </div>

  <!-- itinerary data -->
  <div class="row border-bottom border-2 py-2">
    <div class="col-12">
      <div class="d-flex text-white bg-secondary border rounded shadow mt-1 py-1 ps-3">
        <h6 #itineraryHeader class="m-0 me-auto" style="line-height: 1.5;">
          {{dictionary['NaklVykl'][country]}}
        </h6>
      </div>
    </div>

    <!-- inicializace 2(nakladka a vykladka) pri vytvareni zakazky -->
    <div class="col-12" style="position: relative">
      <div *ngFor="let it of itinerary; let i=index" class="col-12 pb-2" style="position: relative">
        <h5 *ngIf="i != 0" class="route-part-info px-2">
          <div *ngIf="!computingDirections">
            <span>~</span>
            <span *ngIf="it.route_part_length">{{it.route_part_length}}km</span>
            <span *ngIf="!it.route_part_length">0km</span>
          </div>
          <div *ngIf="computingDirections">
            <div class="spinner-wrapper px-3">
              <div class="spinner-border text-primary" style="width:1.5rem; height:1.5rem;" role="status">
                <span class="sr-only"></span>
              </div>
            </div>
          </div>
        </h5>
        <div class="row">
          <div class="col-md-3 col-sm-4">
            <div class="form-group mb-1">
              <label>Typ</label> 
              <select class="form-select border-success" [(ngModel)]="it.type"
              [style.background-color]="it.backgroundType">
                <option value="L" style="background: #fff">{{dictionary['Nakládka'][country]}}</option>
                <option value="U" style="background: #fff">{{dictionary['Vykládka'][country]}}</option>
                <option value="X" style="background: #fff">{{dictionary['Tranzit'][country]}}</option>
                <option value="M" style="background: #fff">{{dictionary['Přejezd'][country]}}</option>
                <option value="F" style="background: #fff">{{dictionary['Tankování'][country]}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-9 col-sm-8">
            <div class="form-group mb-1">
              <label class="me-1">{{dictionary['Adresa'][country]}}</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <img class="pointer" height="28px" src="../../../assets/img/google_icon.png"/>
                  </span>
                </div>

                <!-- google places autocomplete -->
                <input [id]="'google-input' + it.position" 
                class="form-control border-{{!it.gps_coord ? 'danger' : 'success'}}"
                type="text" [(ngModel)]="it.address"
                placeholder="Dle GoogleMaps vepište přesnou adresu (firma, ulice, město) a potvrďte z nabízených možností."/>

                <button class="btn btn-outline-primary" type="button" [disabled]="!it.marker"
                (click)="itineraryMap = it" data-bs-toggle="modal" data-bs-target="#mapModal">
                  <img class="pointer" height="25px" src="../../../assets/img/map_location2.svg" 
                  ngbTooltip="Zobrazit na mapě" placement="auto" container="body"/>
                </button>
              </div>
            </div>
          </div>

          <!-- arrival time should be displayed for all but not for type M (Prejezd) -->
          <div class="col-md-4 col-sm-6">
            <div class="form-group mb-1">
              <label>{{dictionary['DatumČas'][country]}}</label>
              <input type="datetime-local" [(ngModel)]="it.arrival_time_custom"
              class="form-control border-{{!it.arrival_time_custom ? 'danger' : 'success'}}"
              (ngModelChange)="autocomputeTimeEstimation(i)"/>
            </div>
          </div>
          <div *ngIf="it.type == 'L' || it.type == 'U'" class="col-md-2 col-sm-6">
            <div class="form-group mb-1">
              <label class="text-nowrap">{{dictionary['Limit'][country]}}</label>
              <input class="form-control" type="number"
              [(ngModel)]="it.loading_time_limit" />
            </div>
          </div>
          <div *ngIf="it.type == 'L' || it.type == 'U'" class="col-md-6 col-sm-12">
            <div class="form-group mb-1">
              <div class="custom-slider" [ngbTooltip]="dictionary['ČasovéOkno'][country]">
                <ngx-slider ng5-slider [(value)]="it.work_day_begin_minutes"
                [options]="options" [(highValue)]="it.work_day_end_minutes"
                (userChange)="changeWorkDayBegin(it); changeWorkDayEnd(it)"></ngx-slider>
              </div>
            </div>
          </div>

          <div *ngIf="it.type == 'L' || it.type == 'U'" class="col-4">
            <div class="form-group mb-1">
              <label>{{dictionary['Hmotnost'][country]}}</label>
              <div class="input-group input-group-sm input-group-prepend">
                <input type="number" class="form-control" min="0" [(ngModel)]="it.weight"
                (change)="setObligationWeight()"/>
                <div class="input-group-append">
                  <span class="input-group-text px-1">{{dictionary['Tun'][country]}}</span>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="it.type == 'L' || it.type == 'U'" class="col-4">
            <div class="form-group mb-1">
              <label>{{dictionary['Množství'][country]}}</label>
              <input type="number" class="form-control" [(ngModel)]="it.ware_pcs"/>
            </div>
          </div>
          <div *ngIf="it.type == 'L' || it.type == 'U'" class="col-4">
            <div class="form-group mb-1">
              <label>{{dictionary['Balení'][country]}}</label>
              <input type="text" class="form-control"
              [(ngModel)]="it.ware_type" placeholder="např. ks, PAL, EUR, COL, CRT ..."/>
            </div>
          </div>
          <div *ngIf="it.type == 'L' || it.type == 'U'" class="col-sm-8">
            <div class="form-group mb-2">
              <label>{{dictionary['Poznámka'][country]}}</label>
              <textarea class="form-control" 
              style="height: 35px;" [(ngModel)]="it.note"></textarea>
            </div>
          </div>
          <div *ngIf="it.type == 'X' || it.type == 'F' || it.type == 'W' || it.type == 'M'" class="col-sm-4">
            <div class="form-group mb-2">
              <label>{{dictionary['Poznámka'][country]}}</label>
              <textarea class="form-control" 
              style="height: 35px;" [(ngModel)]="it.note"></textarea>
            </div>
          </div>
          
          <div class="col-sm-4 d-flex justify-content-end">
            <div *ngIf="it.completed" class="form-group mb-1 me-1">
              <label>&nbsp;</label>
              <button class="btn btn-outline-success form-control" 
              style="cursor: default">
                <img src="../../../assets/img/check-green-transition.svg"
                width="20px" height="20px" placement="auto" 
                [ngbTooltip]="dictionary['Dokončena'][country]"/>
              </button>
            </div>
            <div class="form-group mb-1 me-1">
              <label>&nbsp;</label>
              <button class="btn btn-primary form-control" 
              [ngbTooltip]="dictionary['Výše'][country]"
              (click)="positionUp(it.position, itineraryHeader, itineraryNewRow);">
                <img class="align-middle" src="../../../assets/img/navigate_up_white.svg" width="18px" height="18px" alt="Up"/>
              </button>
            </div>
            <div class="form-group mb-1 me-2">
              <label>&nbsp;</label>
              <button class="btn btn-primary form-control align-top" 
              [ngbTooltip]="dictionary['Níže'][country]"
              (click)="positionDown(it.position, itineraryHeader, itineraryNewRow);">
                <img class="align-middle" src="../../../assets/img/navigate_down_white.svg" width="18px" height="18px" alt="Down"/>
              </button>
            </div>
            <div class="form-group mb-1">
              <label>&nbsp;</label>
              <button class="btn btn-danger form-control" type="button" 
              [ngbTooltip]="dictionary['Odebrat'][country]"
              (click)="removeItinerary(it.position)">
                X
              </button>
            </div>
          </div>
        </div>

        <hr class="mt-2 mb-0" style="border-top: dashed 12px;"/>
      </div>

      <div class="row my-3">
        <div #itineraryNewRow class="col-sm-4 mb-2">
          <button class="btn btn-warning form-control mb-1" role="button" 
          (click)="addDefaultItinerary()">
            {{dictionary['Přidat'][country]}}
          </button>
        </div>
        <div class="col-sm-8 mb-2">
          <button class="btn btn-success form-control mb-1" role="button" 
          data-bs-toggle="modal" data-bs-target="#confirmationModal"
          [disabled]="!itinerary || itinerary.length < 2 || !validItinerary">
            {{dictionary['Odeslat'][country]}}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- History of obligations -->
  <div class="row py-3">
    <div class="col-12">
      <h5>{{dictionary['Odeslat'][country]}}</h5>

      <div class="table-responsive mt-3">
        <table *ngIf="obligations?.length" class="table table-sm">
          <thead class="text-white bg-secondary shadow">
            <tr>
              <th scope="col">{{dictionary['Zakázka'][country]}}</th>
              <th scope="col">{{dictionary['Objednávka'][country]}}</th>
              <th scope="col"></th>
              <th scope="col">{{dictionary['Destinace'][country]}}</th>
              <th scope="col">{{dictionary['Trasa'][country]}}</th>
              <th scope="col">{{dictionary['DatumNakl'][country]}}</th>
              <th scope="col">{{dictionary['Pořízeno'][country]}}</th>
            </tr>

            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col">
                <div class="input-group input-group-sm" style="min-height:22px; height:22px">
                  <input class="form-control form-control-sm py-0 px-1" type="text" 
                  style="min-height:22px; height:22px" placeholder="Zadejte adresu" 
                  [(ngModel)]="filterItinAddr" (keydown.enter)="filterChange()"/>
                  <div class="input-group-append" style="min-height:22px; height:22px">
                    <span class="input-group-text py-0" style="cursor: pointer; min-height:22px; height:22px">
                      <img src="../../../assets/img/search-solid.svg" height="14px"
                      ngbTooltip="Vyhledat" placement="auto" (click)="filterChange()"/>
                    </span>
                  </div>
                </div>
              </th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let o of obligations">
              <td class="text-nowrap align-middle">
                ZA{{o.order_number_standard}}
              </td>
              <td class="text-nowrap align-middle">
                {{o.order_number}}
              </td>
              <td class="text-nowrap">
                <div class="d-flex justify-content-end">
                  <button class="btn btn-primary btn-sm" (click)="duplicate(o)">
                    {{dictionary['Znovu'][country]}}
                  </button>
                </div>
              </td>
              <td>
                <div *ngIf="o.itinerary" class="text-nowrap align-middle">
                  <span *ngFor="let it of o.itinerary; let i=index">
                    <span *ngIf="it.type == 'L' || it.type == 'U'">
                      {{it.place_country}} {{it.place_city}}
                      {{(i < o.itinerary.length - 1) ? '>> ' : ''}}
                    </span>
                  </span>
                </div>
              </td>
              <td class="text-nowrap align-middle">
                {{o.route_length_rounded}}km
              </td>
              <td class="text-nowrap align-middle">
                <div *ngIf="o.first_itinerary_time">
                  {{o.first_itinerary_time | date: 'dd.MM. HH:mm'}}
                </div>
              </td>
              <td class="text-nowrap align-middle">
                <div *ngIf="o.created_time_date">
                  {{o.created_time_date | date: 'dd.MM.yyyy'}}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      
      <div class="col-xl-12 d-flex justify-content-center my-2">
        <button type="button" class="btn btn-primary mr-4"
        ngbTooltip="Přechozí stránka" container="body" placement="top auto"
        [disabled]="!hasPreviousPage()" (click)="filterSortObligations(-1)">
          {{'\<\<'}}
        </button>
    
        <button type="button" class="btn btn-secondary w-25 mx-5">
          {{dictionary['Strana'][country]}} {{currentPage + 1}}
        </button>
        
        <button type="button" class="btn btn-primary ml-4"
        ngbTooltip="Další stránka" container="body" placement="top auto"
        [disabled]="!hasNextPage()" (click)="filterSortObligations(1)">
          {{'\>\>'}}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Confirmation of creating obligation -->
<div class="modal fade" id="confirmationModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 *ngIf="dictionary" class="modal-title">
          {{dictionary['Odeslat'][country]}}
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div *ngIf="dictionary">
          <h6>{{dictionary['OdeslatOvěření1'][country]}}</h6>
          <h6>{{dictionary['OdeslatOvěření2'][country]}}</h6>
        </div>
      </div>
      <div *ngIf="dictionary" class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          {{dictionary['Zrušit'][country]}}
        </button>
        <button type="button" class="btn btn-success" 
        (click)="createObligation()" data-bs-dismiss="modal">
          {{dictionary['Potvrdit'][country]}}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Itinerary position on google map modal -->
<div class="modal fade" id="mapModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <h5 *ngIf="dictionary" class="modal-title">
          {{dictionary['ZobrazeníMapa'][country]}}
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div *ngIf="itineraryMap && dictionary" class="row mb-2">
          <div class="google-map-wrapper mb-3">
            <app-google-map [marker]="itineraryMap.marker"></app-google-map>
          </div>

          <div class="col-xl-3 col-lg-4">
            <div class="form-group mb-0">
              <label class="normal-font-size">{{dictionary['Firma'][country]}}</label>
              <input class="form-control" type="text" [(ngModel)]="itineraryMap.place_name"/>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4">
            <div class="form-group mb-0">
              <label class="normal-font-size">{{dictionary['Ulice'][country]}}</label>
              <input class="form-control" type="text" [(ngModel)]="itineraryMap.place_street"/>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4">
            <div class="form-group mb-0">
              <label class="normal-font-size">{{dictionary['Město'][country]}}</label>
              <input class="form-control" type="text" [(ngModel)]="itineraryMap.place_city"/>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4">
            <div class="form-group mb-0">
              <label class="normal-font-size">{{dictionary['PSČ'][country]}}</label>
              <input class="form-control" type="text" [(ngModel)]="itineraryMap.place_zip"/>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4">
            <div class="form-group mb-0">
              <label class="normal-font-size">{{dictionary['Stát'][country]}}</label>
              <select class="form-select" [(ngModel)]="itineraryMap.place_country">
                <option [ngValue]="null">Zvolte stát (MPZ) nakládky/vykládky</option>
                <option value="AL">AL-Albánie</option>
                <option value="AND">AND-Andora</option>
                <option value="ARM">ARM-Arménie</option>
                <option value="AT">AT-Rakousko</option>
                <option value="AZ">AZ-Ázerbajdžán</option>
                <option value="BE">BE-Belgie</option>
                <option value="BG">BG-Bulharsko</option>
                <option value="BIH">BIH-Bosna a Hercegovina</option>
                <option value="BY">BY-Bělorusko</option>
                <option value="CY">CY-Kypr</option>
                <option value="CZ">CZ-Česká republika</option>
                <option value="DE">DE-Německo</option>
                <option value="DK">DK-Dánsko</option>
                <option value="EE">EE-Estonsko</option>
                <option value="EL">EL-Řecko</option>
                <option value="ES">ES-Španělsko</option>
                <option value="FI">FI-Finsko</option>
                <option value="FL">FL-Lichtenštejnsko</option>
                <option value="FR">FR-Francie</option>
                <option value="GB">GB-Velká Británie</option>
                <option value="GE">GE-Gruzie</option>
                <option value="HR">HR-Chorvatsko</option>
                <option value="HU">HU-Maďarsko</option>
                <option value="CH">CH-Švýcarsko</option>
                <option value="IE">IE-Irsko</option>
                <option value="IT">IT-Itálie</option>
                <option value="KZ">KZ-Kazachstán</option>
                <option value="LT">LT-Litva</option>
                <option value="LU">LU-Lucembursko</option>
                <option value="LV">LV-Lotyšsko</option>
                <option value="MD">MD-Moldavsko</option>
                <option value="MNE">MNE-Černá hora</option>
                <option value="MT">MT-Malta</option>
                <option value="NL">NL-Nizozemsko</option>
                <option value="NMK">NMK-Severní Makedonie</option>
                <option value="PL">PL-Polsko</option>
                <option value="PT">PT-Portugalsko</option>
                <option value="RO">RO-Rumunsko</option>
                <option value="RSM">RSM-San Marino</option>
                <option value="RUS">RUS-Rusko</option>
                <option value="SE">SE-Švédsko</option>
                <option value="SI">SI-Slovinsko</option>
                <option value="SK">SK-Slovensko</option>
                <option value="SRB">SRB-Srbsko</option>
                <option value="TR">TR-Turecko</option>
                <option value="UA">UA-Ukrajina</option>
              </select>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4">
            <div class="form-group mb-0">
              <label class="normal-font-size">{{dictionary['GPS'][country]}}</label>
              <input class="form-control" type="text" [ngModel]="itineraryMap.gps_coord" disabled/>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="form-group mb-0">
              <label class="normal-font-size">{{dictionary['Adresa'][country]}}</label>
              <input class="form-control" type="text" [(ngModel)]="itineraryMap.address"/>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="dictionary" class="modal-footer">
        <button type="button" class="btn btn-success" data-bs-dismiss="modal">
          {{dictionary['OK'][country]}}
        </button>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          {{dictionary['Zavřít'][country]}}
        </button>
      </div>
    </div>
  </div>
</div>