import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

declare var google: any;

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.css']
})
export class GoogleMapComponent implements OnInit, OnDestroy {
  
  private _subscriptions: Array<Subscription> = [];

  private _map: any = null;

  private _key: number;
  get key(): number {
    return this._key;
  }

  // markers could be provided from component
  private _marker: any = null;
  @Input()
  public set marker(value: any) {
    this.clearMarkers();
    this._marker = value;
    this.buildData();
  }

  constructor(
    private _elRef: ElementRef
  ) { 
    this._key = Math.round((new Date()).getTime() / (Math.random() * 1024));
  }

  ngOnInit(): void {
    // this.buildData();
  }
  
  ngOnDestroy() {
    this.clearMarkers();
    this._map = null;
    
    this._subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
    this._subscriptions = [];
  }

  clearMarkers(): void {
    if (this._marker) {
      this._marker.clearEvents();
      this._marker.map = null;
    }
    this._marker = null;
  }

  
  /*************************************************************/
  // Bulding methods
  /*************************************************************/
  private buildData(): void {
    window.setTimeout(
      () => {
        if (!this._map) {
          let container = this._elRef.nativeElement.children[0];
          let options: any = {
            center: new google.maps.LatLng(49.560852, 15.946655),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            scaleControl: true,
            scrollwheel: true,
            zoom: 10
          };
          this._map = new google.maps.Map(container, options);
        }

        if (this._marker && this._marker.position) {
          this._marker.map = this._map;
          // reinit possibly previous zoomed
          this._map.setZoom(this._DEFAULT_ZOOM);
          this._map.setCenter(this._marker.position);
          this._map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
          this._centeredSwitch = false;
        }
      }, 500
    );
  }


  /*************************************************************/
  // Centering feature
  /*************************************************************/
  private _centeredSwitch: boolean = false;
  private _DEFAULT_ZOOM: number = 7;
  private _FOCUS_ZOOM: number = 18;

  public centerToMarker(): void {
    if (this._marker) {
      if (!this._centeredSwitch) {
        this._map.setZoom(this._FOCUS_ZOOM);
        this._map.setCenter(this._marker.position);
        this._map.setMapTypeId(google.maps.MapTypeId.HYBRID);
  
        this._centeredSwitch = true;
      } 
      else {
        this._map.setZoom(this._DEFAULT_ZOOM);
        this._map.setCenter(this._marker.position);
        this._map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
  
        this._centeredSwitch = false;
      }
    }
  }
}
