import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { serviceConfiguration } from '../config';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
    private _http: HttpClient
  ) { 
  }

  // GET /external/orders/?token=<TOKEN>&order=<ORDER>
  getExternalOrder(token: string | null, order: string | null): Observable<any> {
    let url: string = serviceConfiguration.orders.api;
    if (order && token) {
      url = url.replace('<ORDER>', order.toString());
      url = url.replace('<TOKEN>', token);
    }

    return this._http.get(url);
  }

  // GET /external/orders/?token=<TOKEN>&order=<ORDER>&tin=<TIN>
  // return company object when user with TIN uses our TruckAgenda
  getExternalOrderTruckAgenda(token: string | null, order: string | null, tin: string | null): Observable<any> {
    let url: string = serviceConfiguration.orders.apiTinTA;
    if (order && token && tin) {
      url = url.replace('<ORDER>', order.toString());
      url = url.replace('<TOKEN>', token);
      url = url.replace('<TIN>', tin);
    }

    return this._http.get(url);
  }

  // PUT /external/orders/?token=<TOKEN>&order=<ORDER>
  updateExternalOrder(token: string | null, order: string | null, lang: string, data: any): Observable<any> {
    let url: string = serviceConfiguration.orders.api;
    if (order && token) {
      url = url.replace('<ORDER>', order);
      url = url.replace('<TOKEN>', token);
      url += '&lang=' + lang;
    }

    return this._http.put(url, data);
  }


  // GET /external/orders/?token=<TOKEN>&order=<ORDER>&obligation=<OBLIGATION>
  getAttachmentFromObligation(path: string, token: string | null, order: string | null, obligation: string | null): Observable<any> {
    let url: string = serviceConfiguration.orders.apiAttachmentGet;
    if (path && order && obligation && token) {
      url = url.replace('<FILE>', path);
      url = url.replace('<ORDER>', order);
      url = url.replace('<OBLIGATION>', obligation);
      url = url.replace('<TOKEN>', token);
    }
    
    return this._http.get(url, {responseType: 'blob'});
  }

  // PUT /external/orders/?token=<TOKEN>&order=<ORDER>&obligation=<OBLIGATION>
  uploadAttachmentToObligation(token: string | null, order: string | null, obligation: string | null, file: any): Observable<any> {
    let url: string = serviceConfiguration.orders.apiAttachmentPost;
    if (order && obligation && token) {
      url = url.replace('<ORDER>', order);
      url = url.replace('<OBLIGATION>', obligation);
      url = url.replace('<TOKEN>', token);
    }
    
    let formData = new FormData();
    formData.append('file', file);

    return this._http.post(url, formData);
  }

  // POST /external/orders/invoice/?token=<TOKEN>&order=<ORDER>&obligation=<OBLIGATION>
  createInvoice(token: string | null, order: string | null, obligation: string | null, lang: string, data: any, file: any): Observable<any> {
    let url: string = serviceConfiguration.orders.apiInvoicePost;
    if (order && obligation && token) {
      url = url.replace('<ORDER>', order);
      url = url.replace('<OBLIGATION>', obligation);
      url = url.replace('<TOKEN>', token);
      url += '&lang=' + lang;
    }

    let formData = new FormData();
    // for (let key in data) {
    //   formData.append(key, data[key]);
    // }
    formData.append('data', JSON.stringify(data));
    formData.append('file', file);

    return this._http.post(url, formData);
  }

  // GET /external/orders/company/?token=<TOKEN>&order=<ORDER>
  getCompany(token: string | null, order: string | null): Observable<any> {
    let url: string = serviceConfiguration.orders.apiCompany;
    if (token && order) {
      url = url.replace('<TOKEN>', token);
      url = url.replace('<ORDER>', order);
    }

    return this._http.get(url);
  }

  // GET /external/orders/settings/docs/?token=<TOKEN>&order=<ORDER>
  getSettingsDocs(token: string | null, order: string | null): Observable<any> {
    let url: string = serviceConfiguration.orders.apiSettingsDocs;
    if (token && order) {
      url = url.replace('<TOKEN>', token);
      url = url.replace('<ORDER>', order);
    }

    return this._http.get(url);
  }

  // GET /external/orders/settings/docs/?token=<TOKEN>&order=<ORDER>
  getSettingsInvoicing(token: string | null, order: string | null): Observable<any> {
    let url: string = serviceConfiguration.orders.apiInvoicing;
    if (token && order) {
      url = url.replace('<TOKEN>', token);
      url = url.replace('<ORDER>', order);
    }

    return this._http.get(url);
  }

  // GET /external/orders/settings/logo/?token=<TOKEN>&order=<ORDER>
  getLogo(token: string | null, order: string | null): Observable<any> {
    let url: string = serviceConfiguration.orders.apiLogo;
    if (token && order) {
      url = url.replace('<TOKEN>', token);
      url = url.replace('<ORDER>', order);
    }

    return this._http.get(url, {responseType: 'blob'});
  }

  // GET /external/orders/pdf?token=<TOKEN>&order=<ORDER>
  getOrderPdf(token: string | null, order: string | null): Observable<any> {
    let url: string = serviceConfiguration.orders.apiPdf;
    if (token && order) {
      url = url.replace('<TOKEN>', token);
      url = url.replace('<ORDER>', order);
    }

    return this._http.get(url, {responseType: 'blob'});
  }
}
