<div *ngIf="itinerary?.length && orderDictionary && diaryDictionary" class="bg-secondary-dark">
  <!-- height: 100vh;  -->
  <div *ngIf="!showDocuments" style="min-height: 600px; max-width: 600px;" 
  class="container border rounded bg-secondary-dark border-secondary">
    <h4 class="my-2 text-center text-white">
      {{diaryDictionary['Dispozice'][country]}}
      {{itinerary.length ? (itinerary[0].arrival_time_date | date:'dd.MM.yyyy') : ''}}
    </h4>

    <div *ngFor="let it of itinerary" class="row pt-3 border-top border-bottom">
      <ng-container *ngIf="it.type == 'L' || it.type == 'U' || it.type == 'W' || it.type == 'V' || it.type=='T'">
        <div [style]="it.completed ? 'opacity: 0.6' : 'opacity: 1'">
          <div class="d-flex align-items-top">
            <img *ngIf="it.type == 'L'" height="40px"
            src="../../../assets/img/loading-outline-white.svg"/>
            <img *ngIf="it.type == 'U'" height="40px"
            src="../../../assets/img/unloading-outline-white.svg"/>
            <span *ngIf="order?.delivery_type && (it.type=='W' || it.type=='V' || it.type=='T')">
              <img *ngIf="order?.delivery_type == 'D'" 
              class="me-2" src="../../../assets/img/loading-outline-white.svg" alt="icon" height="40px"/>
              <img *ngIf="order?.delivery_type == 'P'" 
              class="me-2" src="../../../assets/img/unloading-outline-white.svg" alt="icon" height="40px"/>
              <!-- {{it.typeName}} -->
            </span>
  
            <div class="text-white ms-3">
              <h5 class="mb-0">
                <strong>
                  {{it.typeName}} - {{it.arrival_time_date | date: 'dd.MM.yyyy HH:mm'}}
                </strong>
              </h5>
              <div class="mb-0">
                {{diaryDictionary['LzeOd'][country]}} {{it.work_day_begin}} 
                {{diaryDictionary['Do'][country]}} {{it.work_day_end}}
              </div>
              <div *ngIf="it.obligation?.order_number_standard" class="mb-0">
                {{it.obligation.order_number_standard}}
              </div>
            </div>
          </div>
        </div>
  
        <div class="col-12 mt-1" [style]="it.completed ? 'opacity: 0.6' : 'opacity: 1'">
          <div class="text-white border rounded mx-1 p-1">
            <div class="mb-0">
              {{it.address}}
            </div>
            <div *ngIf="it.obligation?.code" class="mb-0">
              CODE: {{it.obligation.code}}
            </div>
            <div *ngIf="it.weight || it.ware_pcs" class="mb-0">
              {{it.weight}}t, {{it.ware_pcs}} {{it.ware_type}}
            </div>
            <div *ngIf="it.note" class="mb-0">
              {{diaryDictionary['Poznámka'][country]}}: {{it.note}}
            </div>
          </div>
        </div>
  
        <div class="col-12">
          <div class="d-flex">
            <div class="pe-1" style="flex:1">
              <a *ngIf="it.gps_coord_normalized" class="btn btn-primary my-2 px-1 w-100" type="button"
              [href]="'geo:' + it.gps_coord_normalized | safeResource" target="_blank">
                {{diaryDictionary['Naviguj'][country]}}
              </a>
            </div>
            <div class="px-1" style="flex:1">
              <a *ngIf="it.gps_coord_google" class="btn btn-outline-primary my-2 px-1 w-100" type="button"
              [href]="it.gps_coord_google | safeResource" target="_blank">
                <img src="../../../assets/img/google_icon.png" alt="google"/>
              </a>
            </div>
            <!-- <div class="px-1" style="flex:1">
              <button class="btn btn-info my-2 px-1 w-100" (click)="handleGeolocation()">
                {{diaryDictionary['GPS'][country]}}
              </button>
            </div> -->
            <div class="px-1" style="flex:1">
              <button class="btn btn-{{it.type == 'L' ? 'success' : 'warning'}} my-2 px-1 w-100" 
              (click)="completeItineraryShow.emit(it)" [disabled]="it.completed">
                {{it.type == 'L' ? diaryDictionary['Nalož'][country] : diaryDictionary['Vylož'][country]}}
              </button>
            </div>
            <div class="ps-1" style="flex:1">
              <button class="btn btn-secondary my-2 px-1 w-100" (click)="showDocuments = true">
                {{diaryDictionary['Soubor'][country]}}
              </button>
            </div>
            <!-- <div class="col-3">
              <button class="btn btn-danger my-2 w-100" type="button" 
              (click)="showCmr = true" disabled>
                CMR
              </button> 
            </div> -->
          </div>
        </div>
      </ng-container>
    </div>

    <div class="text-white py-3">
      <h6>{{diaryDictionary['SwiftScan'][country]}}</h6>
      <div class="d-flex justify-content-between">
        <div class="text-center w-50">
          <a href="https://play.google.com/store/apps/details?id=net.doo.snap" target="_blank">
            <img src="../../../assets/img/playstore_en.svg" style="width:75%;"/>
          </a>
        </div>
        <div class="text-center w-50">
          <a href="https://apps.apple.com/app/apple-store/id834854351" target="_blank">
            <img src="../../../assets/img/appstore_en.svg" style="width:68%;"/>
          </a>
        </div>
      </div>
      <h6 class="fst-italic mt-2">
        {{diaryDictionary['Soubory'][country]}}
      </h6>
    </div>
  </div>
  
  <!-- height: 100vh;  -->
  <div *ngIf="showDocuments" class="container border rounded bg-secondary-dark text-white py-3"
  style="min-height: 600px; max-width: 600px;">
    <div class="d-flex justify-content-between mb-3">
      <h5>{{diaryDictionary['VložitDokumenty'][country]}}</h5>
      <div>
        <button class="btn btn-warning" (click)="showDocuments = false">
          {{diaryDictionary['Zpět'][country]}}
        </button>
      </div>
    </div>
    <div class="border border-info rounded">
      <div class="file-input border-bottom">
        <input type="file" multiple (change)="droppedFile($event)"/>

        <div class="mt-2 mx-auto text-center" style="width:90%;"
        [innerHtml]="orderDictionary['Dokumentace-input-text1'][country]">
        </div>

        <div class="d-flex justify-content-center mx-auto mt-2" 
        style="width: 90%; position: relative; z-index: 42;">
          <div class="me-2">
            <a href="javascript:void(0)" 
            (click)="openImgPattern('../../../assets/img/vzor-spatny1.jpg')">
              <img src="../../../assets/img/vzor-spatny1.jpg" height="80px"/>
            </a>
          </div>
          <div class="me-2">
            <a href="javascript:void(0)" 
            (click)="openImgPattern('../../../assets/img/vzor-spatny2.jpg')">
              <img src="../../../assets/img/vzor-spatny2.jpg" height="80px"/>
            </a>
          </div>
          <div class="me-2">
            <a href="javascript:void(0)" 
            (click)="openImgPattern('../../../assets/img/vzor-spatny3.jpg')">
              <img src="../../../assets/img/vzor-spatny3.jpg" height="80px"/>
            </a>
          </div>
          <div>
            <a href="javascript:void(0)" 
            (click)="openImgPattern('../../../assets/img/vzor-dobry.jpg')">
              <img src="../../../assets/img/vzor-dobry.jpg" height="80px"/>
            </a>
          </div>
        </div>
        
        <div class="mt-2 mx-auto text-center" style="width:90%;"
        [innerHtml]="orderDictionary['Dokumentace-input-text2'][country]">
        </div>
        
        <div class="w-100 my-3 text-center">
          <img height="40px" src="../../../assets/img/folder_document.svg"/>
        </div>
      </div>
      
      <!-- order confirmation mode -->
      <div *ngIf="order && externalAttachments.length" class="py-2">
        <div class="d-flex justify-content-start flex-wrap">
          <div *ngFor="let f of externalAttachments" class="p-2">
            <div *ngIf="f.thumbnail" class="thumbnail border rounded shadow" 
            [ngbTooltip]="f.name" (click)="downloadFile(f)">
              <img class="thumbnail" [src]="f.thumbnail.content | safeResource" alt="Náhled"/>
            </div>
            <div *ngIf="!f.thumbnail" class="thumbnail border rounded shadow"
            [ngbTooltip]="f.name" (click)="downloadFile(f)">
              <img class="thumbnail" src="../../../assets/img/folder_document.svg" alt="Náhled"/>
            </div>
          </div>
        </div>
      </div>

      <!-- obligation - web TM mode -->
      <div *ngIf="obligation && webTMAttachments.length" class="py-2">
        <div class="d-flex justify-content-start flex-wrap">
          <div *ngFor="let f of webTMAttachments" class="p-2">
            <div *ngIf="f.thumbnail" class="thumbnail border rounded shadow" 
            [ngbTooltip]="f.name" (click)="downloadFile(f)">
              <img class="thumbnail" [src]="f.thumbnail.content | safeResource" alt="Náhled"/>
            </div>
            <div *ngIf="!f.thumbnail" class="thumbnail border rounded shadow"
            [ngbTooltip]="f.name" (click)="downloadFile(f)">
              <img class="thumbnail" src="../../../assets/img/folder_document.svg" alt="Náhled"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>