<div *ngIf="loadingObligation || loadingOrder" class="container text-center">
  <div class="spinner-wrapper py-4">
    <div class="spinner-border text-warning" style="width:4rem; height:4rem;" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
</div>

<div *ngIf="(obligation && !loadingObligation) || (order && !loadingOrder)" 
class="bg-secondary-dark py-3" style="height: 100vh">
  <app-itinerary-diary 
    [token]="input_token"
    [obligation]="obligation"
    [order]="order"
    (completeItineraryShow)="completeItineraryShow($event)"
    (uploadFileEvent)="uploadFileEvent($event)"
    (geolocationErrorEvent)="geolocationErrorEvent($event)"
    (geolocationStringEvent)="geolocationStringEvent($event)"
    (geolocationModalEvent)="openGeolocationModal($event)">
  </app-itinerary-diary>
</div>


<div class="modal fade" id="completeItineraryModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Dokončení
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <app-itinerary-confirmation 
          [itinerary]="itineraryToComplete" 
          [token]="input_token"
          (completed)="completed($event)">
        </app-itinerary-confirmation>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Zavřít
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="geolocationModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header shadow {{geolocationError ? 'bg-danger' : 'bg-warning'}}">
        <h5 class="modal-title">Vaše poloha</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h6 [innerHTML]="geolocationString | safeHtml"></h6>

        <h6 *ngIf="geolocationError" class="mt-3">
          * Doporučený prohlížeč pro využívání všech funkcí je Google Chrome.
        </h6>
        <h6 *ngIf="geolocationError" class="mt-2">
          * V iOS využijte AppStore pro stažení prohlížeče 
          <a href="https://apps.apple.com/us/app/apple-store/id535886823">Google Chrome</a>.
        </h6>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Zavřít
        </button>
      </div>
    </div>
  </div>
</div>