import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { serviceConfiguration } from '../config';

@Injectable({
  providedIn: 'root'
})
export class SmsService {

  constructor(
    private _http: HttpClient
  ) { 
  }


  // method for creating/sending sms
  createSMS(message: string, phone: string, captcha: string): Observable<any> {
    // initialize data
    let data = {
      msg: message,
      phone: phone,
      captcha: captcha,
      handler: 'O2'
    };

    console.log(data);
    let url: string = serviceConfiguration.sms.api;
    return this._http.post(url, data);
  }
}
