import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { serviceConfiguration } from '../config';

@Injectable({
  providedIn: 'root'
})
export class LocalJsonService {

  constructor(
    private _http: HttpClient
  ) {
  }

  getOrderDictionary(): Observable<any> {
    return this._http.get(serviceConfiguration.orders.dictionary);
  }
  
  getObligationsDictionary(): Observable<any> {
    return this._http.get(serviceConfiguration.obligations.dictionary);
  }
  
  getDiaryDictionary(): Observable<any> {
    return this._http.get(serviceConfiguration.diary.dictionary);
  }
}
