import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { serviceConfiguration } from '../config';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(
    private _http: HttpClient
  ) { 
  }

  // POST /files/topdf - this method returns pdf as blob
  convertToPdf(file: File, token: string | null): Observable<any> {
    let url: string = serviceConfiguration.files.apiToPdf;
    if (token) {
      url = url.replace('<TOKEN>', token);
    }

    let formData = new FormData();
    formData.append('file', file);
    let options: any = {
      headers: new HttpHeaders(),
      observe: 'response',
      responseType: 'blob' as 'json'
    };

    return this._http.post(url, formData, options);
  }

  // GET /files/company/thumb/<NAME>?token=<TOKEN>
  getThumbnail(path: string, token: string | null): Observable<Blob> {
    let url: string = serviceConfiguration.files.apiThumbnail;
    if (path && token) {
      url = url.replace('<PATH>', path);
      url = url.replace('<TOKEN>', token);
    }

    return this._http.get(url, {responseType: 'blob'});
  }
}
